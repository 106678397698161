import { PAGE_VIEW, PRODUCT_CLICK } from './../constants';


/**
 * get page view event object!
 * @param {Number} friendId 
 */
export const pageView = productsCount => ({
    type: PAGE_VIEW,
    data: {
        pageName: 'Home',
        productsCount,
    }
})

/**
 * get page view event object!
 */
export const productClick = (page, clickType, category_id, product_id) => ({
    type: PRODUCT_CLICK,
    data: {
        page: page,
        type: clickType, // add_to_cart/click ...        
        category_id: category_id,
        product_id: product_id,
    }
})