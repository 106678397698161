export const SET_SCROLL_DATA = 'SET_SCROLL_DATA';
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const SET_CART_MODAL_OPENED_STATE = 'SET_CART_MODAL_OPENED_STATE';
export const SET_TERMS_DIALOG_OPENED = 'SET_TERMS_DIALOG_OPENED';
export const SET_REFUND_DIALOG_OPENED = 'SET_REFUND_DIALOG_OPENED';
export const SET_PRIVACY_POLICY_OPENED = 'SET_PRIVACY_POLICY_OPENED';
export const SET_LINKS_MODAL_OPENED = 'SET_LINKS_MODAL_OPENED';
export const SET_BOOKING_MODAL_OPENED = 'SET_BOOKING_MODAL_OPENED';
export const SET_DIAL_TOOLTIP_STATE = 'SET_DIAL_TOOLTIP_STATE';
export const SET_ERROR_AND_RELOAD_MODAL_MESSAGE = 'SET_ERROR_AND_RELOAD_MODAL_MESSAGE';

export const SET_BOTTOM_SPEED_DIAL_TOOLTIP_USER_NEEDS_AND_BILL_WANTED_STATE = 'SET_BOTTOM_SPEED_DIAL_TOOLTIP_USER_NEEDS_AND_BILL_WANTED_STATE';
export const SET_BOTTOM_SPEED_DIAL_TOOLTIP_STATE = 'SET_BOTTOM_SPEED_DIAL_TOOLTIP_STATE';

export const SET_USER_NEEDS_MODAL_STATE = 'SET_USER_NEEDS_MODAL_STATE';

export const SET_GOOGLE_MAPS_DELIVERY_ADDRESS_MODAL_STATE = 'SET_GOOGLE_MAPS_DELIVERY_ADDRESS_MODAL_STATE';
export const DISALLOW_ORDERS_FOR_THIS_DEVICE = 'DISALLOW_ORDERS_FOR_THIS_DEVICE';