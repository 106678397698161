import { SEARCH_OPENED, SEARCH_TEXT } from '../actions/types';

const initialState = {
    search_opened: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SEARCH_OPENED:
            return {
                ...state,
                search_opened: action.payload
            }
        case SEARCH_TEXT:
            return {
                ...state,
                search_text: action.payload
            }
        default:
            return state;
    }
}