import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Utils from '../../utils/Utils'
import Auth from '../../utils/Auth';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 150,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  imageTabletMode: {
    height: 365,
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
    boxShadow: 'inset 0 0 20px 0px black',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    fontSize: 17,
    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  },
  imageMarked: {
    height: 3,
    width: 30,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 15px)',
    transition: theme.transitions.create('opacity'),
  },
  imageDescription: {
    color: '#eeeeee',
    'font-style': 'italic'
  }
}));

export default function ButtonBases(props) {
  const classes = useStyles();

  const token_not_spot = Auth.getTokenOrderType() != 1;
  const token_spot = Auth.getTokenOrderType() == 1;
  const token_not_spot_browse = Auth.getTokenOrderType() != 4;
  const token_spot_browse = Auth.getTokenOrderType() == 4;
  const token_not_delivery = Auth.getTokenOrderType() != 2;
  const token_delivery = Auth.getTokenOrderType() == 2;

  return (
    <div className={classes.root}>
      {Object.values(props.items).sort(Utils.sortPriority()).map((item, k) => {

        if (token_not_spot && item.show_cat_only_for_spot_orders == 1) { return null }
        if (token_spot && item.hide_cat_only_for_spot_orders == 1) { return null }
        if (token_not_spot_browse && item.show_only_for_spot_browse == 1) { return null }
        if (token_spot_browse && item.hide_only_for_spot_browse == 1) { return null }
        if (token_delivery && item.hide_cat_only_for_delivery_orders == 1) { return null }
        if (token_not_delivery && item.show_cat_only_for_delivery_orders == 1) { return null }

        return (
          <Grow in={true} timeout={{ enter: Utils.calculateAnimationTimeout(k), exit: Utils.calculateAnimationTimeout(k) }} key={k}>
            <ButtonBase
              focusRipple
              key={item.name}
              className={Auth.isTabletMode() ? clsx(classes.image, classes.imageTabletMode) : classes.image}
              focusVisibleClassName={classes.focusVisible}
              style={{
                width: '33.33%',
              }}
              onClick={() => props.categoryClickHandler(item.id)}
              data-class="category-image-button"
            >
              <span
                className={classes.imageSrc}
                style={{
                  backgroundImage: 'url(' + Utils.getSubcategoryImagePath(item.icon, 1) + ')',
                }}
                data-class="image-category-image-container"
              />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
                <Typography
                  component="h3"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {(Utils.getSettingsValue(props.settings, 'replace_slash_with_new_line_category_list') == 1 ? Utils.replaceString(item.name, '/', "<br/>", 1) : item.name)}
                  <div className={classes.imageDescription} dangerouslySetInnerHTML={{
                    __html: item.description ? item.description : ''
                  }}></div>
                  <span className={classes.imageMarked} />
                </Typography>
              </span>
            </ButtonBase>
          </Grow>
        )
      })}
    </div>
  );
}
