import {
    SET_SCROLL_DATA, SET_ALERT_MESSAGE, SET_CART_MODAL_OPENED_STATE, SET_TERMS_DIALOG_OPENED, SET_PRIVACY_POLICY_OPENED,
    SET_LINKS_MODAL_OPENED, SET_BOOKING_MODAL_OPENED, SET_DIAL_TOOLTIP_STATE, SET_ERROR_AND_RELOAD_MODAL_MESSAGE,
    SET_BOTTOM_SPEED_DIAL_TOOLTIP_USER_NEEDS_AND_BILL_WANTED_STATE, SET_BOTTOM_SPEED_DIAL_TOOLTIP_STATE, SET_USER_NEEDS_MODAL_STATE,
    SET_GOOGLE_MAPS_DELIVERY_ADDRESS_MODAL_STATE,
    DISALLOW_ORDERS_FOR_THIS_DEVICE,
    SET_REFUND_DIALOG_OPENED
} from './types'

export const setDialTooltipState = (st) => dispatch => {
    dispatch({
        type: SET_DIAL_TOOLTIP_STATE,
        payload: st
    })
}

export const setBottomSpeedDialTooltipUserNeedsAndBillWantedState = (st) => dispatch => {
    dispatch({
        type: SET_BOTTOM_SPEED_DIAL_TOOLTIP_USER_NEEDS_AND_BILL_WANTED_STATE,
        payload: st
    })
}

export const setBottomSpeedDialTooltipState = (st) => dispatch => {
    dispatch({
        type: SET_BOTTOM_SPEED_DIAL_TOOLTIP_STATE,
        payload: st
    })
}

export const setUserNeedsModalState = (st) => dispatch => {
    dispatch({
        type: SET_USER_NEEDS_MODAL_STATE,
        payload: st
    })
}

export const setScrollData = (sc) => dispatch => {
    dispatch({
        type: SET_SCROLL_DATA,
        payload: sc
    })
}

export const setAlertMessage = (opened, message = '', success = false) => dispatch => {
    dispatch({
        type: SET_ALERT_MESSAGE,
        payload: { opened, message, success }
    })
}



export const setCartModalOpenedState = (state) => dispatch => {
    dispatch({
        type: SET_CART_MODAL_OPENED_STATE,
        payload: state
    })
}


export const setTermsDialogOpened = (state) => dispatch => {
    dispatch({
        type: SET_TERMS_DIALOG_OPENED,
        payload: state
    })
}

export const setRefundDialogOpened = (state) => dispatch => {
    dispatch({
        type: SET_REFUND_DIALOG_OPENED,
        payload: state
    })
}

export const setPrivacyPolicyDialogOpened = (state) => dispatch => {
    dispatch({
        type: SET_PRIVACY_POLICY_OPENED,
        payload: state
    })
}

export const setLinksModalOpened = (state = false, data) => dispatch => {
    dispatch({
        type: SET_LINKS_MODAL_OPENED,
        payload: state ? data : state
    })
}

export const setBookingModalOpened = (state = false, data) => dispatch => {
    dispatch({
        type: SET_BOOKING_MODAL_OPENED,
        payload: state ? data : state
    })
}

export const setErrorAndReloadModalMessage = (msg = "") => dispatch => {
    dispatch({
        type: SET_ERROR_AND_RELOAD_MODAL_MESSAGE,
        payload: msg
    })
}


export const setGoogleMapsDeliveryAddressModalOpened = (state) => dispatch => {
    dispatch({
        type: SET_GOOGLE_MAPS_DELIVERY_ADDRESS_MODAL_STATE,
        payload: state
    })
}

export const setDisallowOrdersForDevice = () => dispatch => {
    dispatch({
        type: DISALLOW_ORDERS_FOR_THIS_DEVICE,
        payload: 1
    })
}