import { merge } from 'lodash';

import { designV1_CommonCss, designV1_1Css, designV1_2Css } from './designV1';
import { designV2_CommonCss, designV2_1Css, designV2_2Css } from './designV2';
import { designV3_CommonCss, designV3_1Css, designV3_2Css, designV3_3Css } from './designV3';
import { customCssStyles } from './custom'

export const customStyles = customCssStyles;

export const designV1_1 = merge(designV1_1Css, designV1_CommonCss);
export const designV1_2 = merge(designV1_2Css, designV1_CommonCss);

export const designV2_1 = merge(designV2_1Css, designV2_CommonCss);
export const designV2_2 = merge(designV2_2Css, designV2_CommonCss);

export const designV3_1 = merge(designV3_1Css, designV3_CommonCss);
export const designV3_2 = merge(designV3_2Css, designV3_CommonCss);
export const designV3_3 = merge(designV3_3Css, designV3_CommonCss);