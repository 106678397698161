import axios from 'axios';
import { GET_FREE_HOURS_FOR_DATE, GET_BUSY_DATES } from './types';
import { API_URL } from './../../constants';
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';
import history from './../../utils/history';

export const book = (data) => dispatch => {

    return axios.post(API_URL + 'bookings',
        data
    )
    // .then(res => {
    //     console.log(res)
    // }).catch(error => {
    //     dispatch(addErrorMessages(["Моля попълнете коректно всички данни"]));
    // });

}

export const getBusyDates = (data) => dispatch => {
    
    axios.post(API_URL + 'bookings/getBusyDates',
        data
    ).then(res => {
        dispatch({
            type: GET_BUSY_DATES,
            payload: res.data
        });
    }).catch(error => {
        dispatch(addErrorMessages(["Моля попълнете коректно всички данни"]));
    });
}

export const getFreeHoursForDate = (data) => dispatch => {
    
    axios.post(API_URL + 'bookings/getFreeHoursForDate',
        data
    ).then(res => {
        dispatch({
            type: GET_FREE_HOURS_FOR_DATE,
            payload: res.data
        });
    }).catch(error => {
        dispatch(addErrorMessages(["Грешка"]));
    });
}

export const clearFreeHoursForDate = () => dispatch => {
    dispatch({
        type: GET_FREE_HOURS_FOR_DATE,
        payload: []
    });
}