import {
    ADD_USER_NEED_ERROR, ADD_USER_NEED_SUCCESS, RESET_USER_NEED, LOADING_USER_NEED_START,
    LOADING_USER_NEED_END, GET_USER_NEED, GET_USER_NEED_ERROR
} from '../actions/types';

const initialState = {
    user_needs: [],
    user_need_failed: '',
    user_need_added: '',
    isLoadingUserNeeds: false,
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_USER_NEED_ERROR:
            return {
                ...state,
                user_need_failed: action.payload
            }
        case ADD_USER_NEED_SUCCESS:
            return {
                ...state,
                user_need_added: action.payload
            }
        case RESET_USER_NEED:
            return {
                ...state,
                user_need_failed: '',
                user_need_added: '',
            }
        case LOADING_USER_NEED_START:
            return {
                ...state,
                isLoadingUserNeeds: true
            }
        case LOADING_USER_NEED_END:
            return {
                ...state,
                isLoadingUserNeeds: false
            }
        case GET_USER_NEED:
            return {
                ...state,
                user_needs: action.payload
            }
        case GET_USER_NEED_ERROR: {
            return {
                ...state,
                user_needs: []
            }
        }
        default:
            return state;
    }
}