import { GET_FREE_HOURS_FOR_DATE, GET_BUSY_DATES } from './../actions/types';

const initialState = {
    freeHours: [],
    busyDates: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_FREE_HOURS_FOR_DATE:
            return {
                ...state,
                freeHours: action.payload,
            } 
        case GET_BUSY_DATES:
            return {
                ...state,
                busyDates: action.payload,
            }           
        default:
            return state;
    }
}