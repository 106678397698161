import axios from 'axios';
import { VERIFY_TOKEN, VERIFICATION_SUCCESS, VERIFICATION_FAIL, OPEN_SCAN_MODAL, CLOSE_SCAN_MODAL, SET_CURR_OPENED_MODAL, SET_ORDER_MODAL_STATE } from './types';
import { API_URL, DEFAULT_PRODUCT_IMAGE } from '../../constants'
import Utils from '../../utils/Utils';
import Auth from '../../utils/Auth';
import { getUserPaymentInstruments } from './../../users/actions/users.actions';

// export const verifyToken = (token) => dispatch => {
//     // dispatch({ type: LOADING_START });

//     return axios
//         .post(API_URL + 'tables/verify_token', {"token" : token});

//         // .then(res => {
//         //     Auth.setUserToken(token);
//         //     history.push("/");
//         // })
//         // .catch(err => {
//         //     Auth.removeUserToken();
//         //     history.push("/");            
//         // })
// }

export const verifyTokenAndSession = (token, placeSlug = null) => dispatch => {
    // dispatch({ type: LOADING_START });
    if(Auth.getUserToken()) {
        return axios
        .post(API_URL + 'tables/verify_session', {"token" : token, "session": Auth.getUserToken(), "placeSlug": placeSlug});   // in getUserToken() is saved the generated session from scannig the qr code
    } else {
        return axios
        .post(API_URL + 'tables/verify_token', {"token" : token, "placeSlug": placeSlug});
    }


        // .then(res => {
        //     Auth.setUserToken(token);
        //     history.push("/");
        // })
        // .catch(err => {
        //     Auth.removeUserToken();
        //     history.push("/");            
        // })
}

export const setCurrOpenedModal = (currModal) => dispatch => {
    dispatch({
        type: SET_CURR_OPENED_MODAL,
        payload: currModal
    })
} 

export const openScanModal = () => dispatch => {
    dispatch({
        type: OPEN_SCAN_MODAL,
        payload: 1
    })
}

export const closeScanModal = () => dispatch => {
    dispatch({
        type: CLOSE_SCAN_MODAL,
        payload: 0
    })
}

export const setOrderModalState = (state) => dispatch => {
    if(state && Utils.hasFunctionalityModule('payments')) {  // if opening order modal and has payments module, get user payment instruments
        dispatch(getUserPaymentInstruments());
    }
    dispatch({
        type: SET_ORDER_MODAL_STATE,
        payload: state
    })
}

// export const getTheOnlyOrderType = () => dispatch => {
//     window.localStorage.getItem("order_type");
// }

// export const setTheOnlyOrderType = (orderType) => dispatch => {
//     window.localStorage.setItem("order_type", orderType);
// }