import { SET_VARIANT_MODAL_OPENED, SET_SELECTED_PRODUCT, SET_PROMO_PRODUCT_FOR_CATEGORY_MODAL_OPENED
} from '../actions/types';

const initialState = {
   variant_modal_opened: false,
   selected_product: [],
   promo_product_for_category_modal_opened: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_VARIANT_MODAL_OPENED:
            return {
                ...state,
                variant_modal_opened: action.payload
            }
        case SET_SELECTED_PRODUCT:
            return {
                ...state,
                selected_product: action.payload
            }
        case SET_PROMO_PRODUCT_FOR_CATEGORY_MODAL_OPENED: 
            return {
                ...state,
                promo_product_for_category_modal_opened: action.payload
            }
        default:
            return state;
    }
}