import axios from 'axios';
import { API_URL } from '../../constants'

import {
    PROMOTION_CLOSE, PROMOTION_CLICK
} from '../constants'


/**
 * Listener on page view
 * @param {Object} event 
 * @param {Array} eventsHistory 
 */
export const onPromotionClose = (event = {}, eventsHistory) => {
    // For example let's push the recieved event to our Datalyer!
    // console.log("EVENT HEERE", event, eventsHistory)
    // window.dataLayer.push(event)
    
    let data = {
        action_type: "close",
        categoryId: event.data.category_id,
        productId: event.data.product_id,
        page: event.data.page,
    }

    axios
    .post(API_URL + 'logs/user_action', data)
    .then(res => {

    }).catch(error => {
        throw (error);
    });

    // In order to save this event in the history (so we can log it) we should return it!
    // otherwise it will be ignored!
    return event;
}
onPromotionClose.eventType = PROMOTION_CLOSE;



/**
 * Listener on page view
 * @param {Object} event 
 * @param {Array} eventsHistory 
 */
export const onPromotionClick = (event = {}, eventsHistory) => {
    // For example let's push the recieved event to our Datalyer!
    // console.log("EVENT HEERE", event, eventsHistory)
    // window.dataLayer.push(event)
    
    let data = {
        action_type: event.data.type,
        categoryId: event.data.category_id,
        productId: event.data.product_id,
        page: event.data.page,
    }

    axios
    .post(API_URL + 'logs/user_action', data)
    .then(res => {

    }).catch(error => {
        throw (error);
    });

    // In order to save this event in the history (so we can log it) we should return it!
    // otherwise it will be ignored!
    return event;
}
onPromotionClick.eventType = PROMOTION_CLICK;