export const GET_PRODUCTS_BY_SUBCAT = 'GET_PRODUCTS_BY_SUBCAT';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const ADD_PRODUCT_TOOLTIP = 'ADD_PRODUCT_TOOLTIP';
export const SET_ORDER_COMMENT = 'SET_ORDER_COMMENT';
export const ADD_TO_CART = 'ADD_TO_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const ORDER_FAILED = 'ORDER_FAILED';
export const ORDER_SPOT_FAILED = 'ORDER_SPOT_FAILED';
export const ORDERED_SUCCESSFULLY = 'ORDERED_SUCCESSFULLY';
export const BILL_ASKED = 'BILL_ASKED';
export const CLEAR_BILL_ASKED = 'CLEAR_BILL_ASKED';

export const LOADING_CART_START = 'LOADING_CART_START';
export const LOADING_CART_END = 'LOADING_CART_END';
export const LOADING_ORDER_START = 'LOADING_ORDER_START';
export const LOADING_ORDER_END = 'LOADING_ORDER_END';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const ADD_TO_ORDERED = 'ADD_TO_ORDERED';
export const ADD_USER_NEED_ERROR = 'ADD_USER_NEED_ERROR';
export const ADD_USER_NEED_SUCCESS = 'ADD_USER_NEED_SUCCESS';
export const RESET_USER_NEED = 'RESET_USER_NEED';
export const LOADING_USER_NEED_START = 'LOADING_USER_NEED_START';
export const LOADING_USER_NEED_END = 'LOADING_USER_NEED_END';

export const SET_PRODUCT_PAGE_ID = 'SET_PRODUCT_PAGE_ID';
export const REMOVE_PRODUCT_PAGE_ID = 'REMOVE_PRODUCT_PAGE_ID';

export const CHANGE_SELECTED_CART_TAB = 'CHANGE_SELECTED_CART_TAB';

export const GET_ORDERS = 'GET_ORDERS';

export const OPEN_COMMENT_MODAL = 'OPEN_COMMENT_MODAL';
export const COMMENT_MODAL_TITLE = 'COMMENT_MODAL_TITLE';
export const COMMENT_VARIANT_ID = 'COMMENT_VARIANT_ID';

export const PAYMENT_TYPE_MODAL_OPENED = 'PAYMENT_TYPE_MODAL_OPENED';
export const SET_DISABLED_MAKE_ORDER_BUTTON = 'SET_DISABLED_MAKE_ORDER_BUTTON';
export const LIST_INGREDIENTS = 'LIST_INGREDIENTS';
export const PLACE_IMAGE_UPLOADED = 'PLACE_IMAGE_UPLOADED';

export const SET_UPSELLING_PRODUCTS_MODAL_OPENED = 'SET_UPSELLING_PRODUCTS_MODAL_OPENED';