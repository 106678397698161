import {
    PURCHASE_PRODUCT,
    STAR_PRODUCT,
    PAGE_VIEW,
    PRODUCT_CLICK
} from '../constants'
import axios from 'axios';
import { API_URL } from '../../constants'


/**
 * Listener on page view
 * @param {Object} event 
 * @param {Array} eventsHistory 
 */
export const onPageView = (event = {}, eventsHistory) => {
    // For example let's push the recieved event to our Datalyer!
    // console.log("EVENT HEERE", event)
    window.dataLayer.push(event)
    
    // In order to save this event in the history (so we can log it) we should return it!
    // otherwise it will be ignored!
    return event;
}
onPageView.eventType = PAGE_VIEW;




export const onProductClick = (event = {}, eventsHistory) => {
    // For example let's push the recieved event to our Datalyer!
    // console.log("EVENT HEERE", event, eventsHistory)
    // window.dataLayer.push(event)
    
    let data = {
        page: event.data.page,        
        action_type: event.data.type,
        categoryId: event.data.category_id,
        productId: event.data.product_id,
    }

    axios
    .post(API_URL + 'logs/user_action', data)
    .then(res => {

    }).catch(error => {
        throw (error);
    });

    // In order to save this event in the history (so we can log it) we should return it!
    // otherwise it will be ignored!
    return event;
}
onProductClick.eventType = PRODUCT_CLICK;



