import { SET_EPAY_FORM_FIELDS, SET_PAYMENT_STATUS, SET_PAYMENT_KEY, SET_PROCESSING_PAYMENT_MODAL_OPENED } from './types';

const initialState = {
    payment_status: '',
    payment_key: '',

}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PAYMENT_STATUS: 
            return {
                ...state,
                payment_status: action.payload,
            }
        case SET_PAYMENT_KEY: 
            return {
                ...state,
                payment_key: action.payload
            }
        default:
            return state;
    }
}