import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import { Zoom, Fade, Grow } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { setMainBackground } from '../../settings/actions/settings.action';
import ProductsFooterContainer from '../../common/containers/ProductsFooterContainer'
import { FormGroup, RadioGroup, FormControl, Radio, Checkbox, FormControlLabel } from '@material-ui/core/';
// import FormLabel from '@material-ui/core/FormLabel';
import Lightbox from 'react-image-lightbox';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ProductListItem from "./ProductListItem";
import ProductVariantsModal from "../../common/modals/ProductVariant";
import { getProductsBySubcat, addProductTooltip, addToCart, changeSelectedCartTab, setOrderComment, removeProductPageId, setProductPageId, setCommentModalData, getProductIngredients } from '../actions/products.action';
import { showHeaderCategory } from '../../categories/actions/categories.action';
import { setScrollData, setAlertMessage } from '../../menu/menu.actions';
import Page404 from '../../common/errors/404'
import { DEMO } from '../../constants';
import MetaAndOg from '../../common/MetaAndOg'
import AddIcon from '@material-ui/icons/Add';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import TextField from '@material-ui/core/TextField';


import Utils from "../../utils/Utils";
import Auth from "../../utils/Auth";

const styles = theme => ({
    viewProductContainer: {
        // position: 'absolute',
        // width: '100%',
        // height: '100%',
        background: 'white',
        // zIndex: 2,
    },

    productInfoContainer: {
        paddingTop: 80,
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        '@media screen and (max-width: 1000px)': {
            paddingTop: 60,
            gridTemplateColumns: 'none',
            gridTemplateRows: 'auto',
        }
    },

    productImageContainer: {
        textAlign: 'center',
        display: 'block',
        margin: '0 auto',


        // background-image: url(https://p.bigstockphoto.com/GeFvQkBbSLaMdpKXF1Zv_bigstock-Aerial-View-Of-Blue-Lakes-And--227291596.jpg);
        // 'background-size': 'cover',
        width: '100%',
        // maxHeight: 300,

        marginBottom: 30,
        // 'background-attachment': 'fixed',
        /* height: 50vw; */
        /* max-height: 350px; */
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
        position: 'relative',
        overflow: 'hidden',
        transition: 'height .5s',
        // 'background-color': '#eaeaea',


    },
    productImage: {
        maxWidth: '70%',
        '@media screen and (max-width: 1000px)': {
            maxWidth: '50%',
            maxHeight: 288,
        }
    },
    productTextContainer: {
        textAlign: 'center',
        display: 'grid',
        'align-items': 'center'
    },
    productNameContainer: {
        fontSize: 26,
        textTransform: 'uppercase',
        padding: '0 20px',
        '@media screen and (max-width: 1000px)': {
            fontSize: 23,
        }
    },
    productSubContainer: {
        background: '#000',
        color: 'white',
        padding: '10px 0',
        fontSize: 16,
        textTransform: 'uppercase',
        fontStyle: 'italic',
        '@media screen and (max-width: 1000px)': {
            fontSize: 13,
        },
        width: '90%',
        margin: '0 auto',
        maxWidth: 600,
        marginTop: 40,
    },
    productDescriptionContainer: {
        color: '#343434',
        fontSize: 19,
        padding: '0 20px',
        '@media screen and (max-width: 1000px)': {
            fontSize: 17,
        }
    },

    commentTextField: {
        width: '90%',
        margin: '0 auto',
        marginTop: 20,
        maxWidth: 600,
        '& label.Mui-focused': {
            color: '#848484',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(254, 105, 2, .7)',
            },
        },
    },

    ProductVariantsContainer: {
        width: '90%',
        margin: '0 auto',
        maxWidth: 600,
        marginTop: 40,
    },

    ProductIngridientsContainer: {
        width: '90%',
        margin: '0 auto',
        maxWidth: 600,
        marginTop: 40,
    },

    addToCartButton: {
        background: '#ff8100',
        width: '90%',
        margin: '0 auto',
        color: 'white',
        borderRadius: 5,
        padding: 10,
        maxWidth: 600,
        marginTop: 20,

        '&:hover': {
            background: '#ff8100',
        }
    },

    productsContainer: {
        WebkitOverflowScrolling: 'touch',
        width: '90%',
        margin: '0 auto',
        paddingTop: 65,
        paddingBottom: 70, // temp unable to hive navbar fix
    },

    similarProductsContainer: {
        fontSize: 26,
        textTransform: 'uppercase',
        textAlign: 'center',
        marginTop: 60,
        '@media screen and (max-width: 1000px)': {
            marginTop: 60,
            paddingTop: 60,
            borderTop: '1px solid #ccc',
            fontSize: 24,
        }
    },
    errorContainer: {
        color: '#ff0c00f0',
        textAlign: 'center',
        fontWeight: 'bold',
        borderBottom: '1px solid #ff0c00f0',
        // marginTop: 17,
        // marginBottom: 5,
    },

    activeProductVariant: {
        border: '1px solid #ccc',
        'background-color': '#e6e6e6',
        borderRadius: 3,
        '&:hover': {
            'background-color': '#e6e6e6'
        }
    },

    allergensContainer: {
        width: '90%',
        margin: '0 auto',
        marginTop: 20,
        maxWidth: 600,
        textAlign: 'left'
        // textAlign: 'center'
    },
    groupContainer: {
        display: 'block',
    },
    requiredIngredientsRadio: {
        margin: 0,
        color: 'rgb(254, 105, 2)',
        '&.Mui-checked': {
            color: 'rgb(254, 105, 2)',
        },
    },
    variantCheckbox: {
        margin: 0,
        color: 'rgb(254, 105, 2)',
        '&.Mui-checked': {
            color: 'rgb(254, 105, 2)',
        },
    },
    addableIngredientsCheckbox: {
        margin: 0,
        color: '#008017',
        '&.Mui-checked': {
            color: '#008017',
        },
    },
    removableIngredientsCheckbox: {
        margin: 0,
        color: '#c50000',
        '&.Mui-checked': {
            color: '#c50000',
        },
    },
    activeRemovableIngredients: {
        // margin: 0,
        color: '#c50000',
        // '&.Mui-checked': {
        //     color: '#c50000',
        // },
    },
    productIngredientsContainerTitle: {
        background: 'rgba(254, 105, 2, 0.33)',
        padding: 10,
    }
});

class ViewProductPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productComment: '',
            productActiveVariantId: 0,
            viewPageError: false,
            timeout: 0,
            openCommentModal: false,
            commentModalTitle: '',
            commentVariantId: '',
            selectedRequiredProductIngredient: 0,
            selectedAddableProductIngredients: [],
            selectedRemovableProductIngredients: [],
            // checkedIngredient: {}
            photoOpened: false,
        }
    }

    componentDidUpdate() {

        // console.log('updated: ', window.location.href)
        if (this.props.currentProduct
            && this.props.currentProduct.product_variants
            && this.props.currentProduct.product_variants[0]
            && this.props.currentProduct.product_variants[0].id
            && (this.state.productActiveVariantId == 0 || (this.props.currentProduct.product_variants.findIndex(pv => pv.id == this.state.productActiveVariantId) == -1))
        ) {
            this.setState({ productActiveVariantId: this.props.currentProduct.product_variants[0].id })
        }
    }


    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (id, productVariantId = 0, useIngredients = true) => {
        const { translations, currentProduct } = this.props;
        if (currentProduct && currentProduct.min_count_addable_ingredients && currentProduct.min_count_addable_ingredients > 0 && this.state[this.getIngredientStates("addable")].length < currentProduct.min_count_addable_ingredients) {
            // Cant add to cart
            this.setState({ viewPageError: translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.min_count_addable_ingredients_reached ? Utils.translate(translations.translations.pages.products.min_count_addable_ingredients_reached, { value: currentProduct.min_count_addable_ingredients }) : '' });
            this.props.setAlertMessage(true, translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.min_count_addable_ingredients_reached ? Utils.translate(translations.translations.pages.products.min_count_addable_ingredients_reached, { value: currentProduct.min_count_addable_ingredients }) : '');
        } else {
            // Add to cart
            // const currProduct = this.props.subcategory.products.filter(p => p.id == id)[0];
            const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];

            if (currProduct.product_variants) {
                if (currProduct.product_variants.length === 1 || productVariantId > 0) {
                    this.addProdToCart(id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct, useIngredients);
                    this.setState({ viewPageError: false });
                } else {
                    if (this.state.productActiveVariantId === 0) {
                        // Show no variant error
                        this.setState({ viewPageError: translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.variant_not_choosed ? translations.translations.pages.products.variant_not_choosed : '' });
                    } else {
                        this.setState({ viewPageError: false });
                        this.addProdToCart(id, currProduct.product_variants.filter(pv => pv.id == this.state.productActiveVariantId)[0].id, currProduct, useIngredients);
                    }
                }
            }
        }

    }

    // Add variant to cart
    addProdToCart = (product_id, product_variant_id, product, useIngredients = true) => {
        const { product_ingredients } = this.props.currentProduct;
        const requiredProductIngridients = product_ingredients.filter(pi => pi.type == 'required' && this.props.productIngredients[pi.ingredientId] && this.props.productIngredients[pi.ingredientId].active == true);

        let defautRequiredProductIngridient = parseInt(this.state.selectedRequiredProductIngredient);
        if (!defautRequiredProductIngridient) {
            if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
                defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
            }
        }

        const selectedIngredients =
            useIngredients ?
                {
                    required: defautRequiredProductIngridient,
                    addable: this.state.selectedAddableProductIngredients,
                    removable: this.state.selectedRemovableProductIngredients,
                }
                :
                {}

        this.showAddedProductTooltip(product_id, product_variant_id, product, selectedIngredients);
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        // this.props.addToCart(product_id, product_variant_id, product, selectedIngredients);
        this.props.addToCart(product_id, product_variant_id, product, selectedIngredients, this.props.selectedCat && this.props.selectedCat.id || null);

    };


    // Shows tooltip
    showAddedProductTooltip = (id, product_variant_id, product, selectedIngredients) => {
        let totalProdQuantity = 1;
        if (this.props.products && this.props.products.to_order && this.props.products.to_order[0] && this.props.products.to_order[0].length > 0) {
            totalProdQuantity = Utils.getCartProductQuantity(product_variant_id, selectedIngredients);
        }

        // if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
        //     console.log('this.props.products.to_order', this.props.products.to_order)
        //     const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
        //     if (addedProductVariant) {
        //         totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
        //     }
        // }
        let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
        let tooltipText = totalProdQuantity + ' x ' + product.name + ' - /' + choosedProductVariant.size + '/';

        // Add to cart tooltip
        const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
        addProdTooltip(id, tooltipText);
        if (this.state.productComment) {
            this.addCommentToOrder(product_variant_id, this.state.productComment);
            this.setState({ productComment: '' });
        }
        let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
        this.setState({ timeout: timeOut });
    }


    addCommentToOrder = (variantId, comment) => {
        let stateComments = Object.values(this.props.products.order_comment);
        let validatedComments = Utils.validatedCommentObject(stateComments, variantId, comment)
        if (validatedComments) {
            this.props.setOrderComment(validatedComments)
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     console.log('nextProps', nextProps)
    //     // if(nextProps.selectedCat) {
    //     //     this.setState({
    //     //         currHierarchyLevel: nextProps.selectedCat.hierarchyLevel
    //     //     })
    //     // }
    // }



    componentDidMount() {
        let { view_product_page_id, setProductPageId, products, getProductIngredients } = this.props;
        const self = this;
        // getProductIngredients();
        // window.onpopstate = function (event) {

        // console.log('tuka ?', self.props)
        // if (
        //     (view_product_page_id == 0)
        //     || (view_product_page_id > 0 && (this.props.currentProdSlug && this.props.currentProdSlug.length == 0))
        //     || (!this.props.currentProdSlug || (this.props.currentProdSlug && this.props.currentProdSlug.length == 0))
        // ) {

        if (self.props.currentProdSlug.length == 0) {
            setProductPageId(0);
        } else {
            // if (view_product_page_id == 0 && this.props.products && this.props.products.all_products && this.props.currentProdSlug && this.props.currentProdSlug.length > 0) {

            // console.log('products', products)
            // 
            const prodBySlug = Object.values(self.props.products.all_products).filter(p => p.slug == (self.props.currentProdSlug))
            // console.log('prodBySlug', prodBySlug)
            if (self.props.currentProdSlug && self.props.currentProdSlug.length > 0 && prodBySlug.length > 0 && prodBySlug[0] && prodBySlug[0].id) {
                // console.log('NA MAUNT', self.props.currentProdSlug)
                // viewProductPageId = prodBySlug[0].id;
                // console.log('viewProductPageId', prodBySlug[0].id)
                self.props.setProductPageId(prodBySlug[0].id);
            }


        }
        // }
        // alert("location: " + document.location + ", state: " + event.state + ' E: ' + event);
        // console.log('E', event)
        // };

        //     // console.log('state.products', state.products)
        //     console.log('window.location.hash', window.location.hash)
        //     console.log('this.props.products', this.props.products)
        //     if (window.location.hash && window.location.hash.length > 0) {
        //         const prodBySlug = Object.values(this.props.products.all_products).filter(p => p.slug == this.props.currentProdSlug)
        //         console.log('prodBySlug', prodBySlug)
        //         if (prodBySlug.length > 0 && prodBySlug[0] && prodBySlug[0].id) {
        //             // viewProductPageId = prodBySlug[0].id;
        //             console.log('viewProductPageId', prodBySlug[0].id)
        //         }
        //     }
    }

    componentWillReceiveProps(nextProps) {
        const { translations } = nextProps;
        let { view_product_page_id } = nextProps;

        // console.log('nextProps', nextProps)
        // console.log('nextProps.products', nextProps.products)
        // console.log('view_product_page_id', view_product_page_id)

        if (view_product_page_id == 0 && nextProps.products && nextProps.products.all_products && nextProps.currentProdSlug && nextProps.currentProdSlug.length > 0) {
            const prodBySlug = Object.values(nextProps.products.all_products).filter(p => p.slug == nextProps.currentProdSlug)
            // console.log('prodBySlug', prodBySlug)
            if (prodBySlug.length > 0 && prodBySlug[0] && prodBySlug[0].id) {
                // viewProductPageId = prodBySlug[0].id;
                // console.log('viewProductPageId', prodBySlug[0].id)
                // console.log('NA componentWillReceiveProps')

                nextProps.setProductPageId(prodBySlug[0].id);
            }


            // } else {
            //     if (nextProps.currentProdSlug.length == 0 && view_product_page_id != 0) {
            //         nextProps.setProductPageId(0);
            //         // console.log('DA ZATVAR{QM?')
            //     }
        }
        // if (window.location.hash.length > 0) {
        //     view_product_page_id = 
        // }

        //         if (parseInt(nextProps.view_product_page_id) > 0) {
        //         // if (this.state.productActiveVariantId != 0) {
        //             nextProps.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '');
        // //             // } else {
        // //             //     nextProps.showHeaderCategory('');
        // //             //     return
        //         }
    }


    checkedIngredient = (ingridientType, ingridientId) => {
        return this.state[this.getIngredientStates(ingridientType)] ? this.state[this.getIngredientStates(ingridientType)].includes(parseInt(ingridientId)) : false
    }

    getIngredientStates = (type) => {
        return type == 'addable' ? 'selectedAddableProductIngredients' : 'selectedRemovableProductIngredients';
    }

    handleIngridientChangeObject = (e, ingridient) => {
        const { translations } = this.props;
        let ingridientState = this.getIngredientStates(ingridient.type);

        if (this.state[ingridientState]) {
            const index = (this.state[ingridientState].indexOf(parseInt(ingridient.ingredientId)))
            if (index > -1) { // Ingredient was found - remove it
                if (ingridient.type == 'addable' && parseInt(this.props.currentProduct.min_count_addable_ingredients) > 0 && (parseInt(Object.keys(this.state[ingridientState]).length) <= (parseInt(this.props.currentProduct.min_count_addable_ingredients)))) {
                    // Error removing
                    this.setState({ viewPageError: translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.min_count_addable_ingredients_reached ? Utils.translate(translations.translations.pages.products.min_count_addable_ingredients_reached, { value: this.props.currentProduct.min_count_addable_ingredients }) : '' });
                    this.props.setAlertMessage(true, translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.min_count_addable_ingredients_reached ? Utils.translate(translations.translations.pages.products.min_count_addable_ingredients_reached, { value: this.props.currentProduct.min_count_addable_ingredients }) : '');
                } else {
                    // Real remove
                    let st = this.state;
                    st[ingridientState].splice(index, 1)
                    this.setState({ [ingridientState]: st[ingridientState] })
                }
            } else { // Add ingredient
                if (ingridient.type == 'addable' && parseInt(this.props.currentProduct.max_count_addable_ingredients) > 0 && (parseInt(Object.keys(this.state[ingridientState]).length) >= (parseInt(this.props.currentProduct.max_count_addable_ingredients)))) {
                    // Error adding
                    this.setState({ viewPageError: translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.max_count_addable_ingredients_reached ? Utils.translate(translations.translations.pages.products.max_count_addable_ingredients_reached, { value: this.props.currentProduct.max_count_addable_ingredients }) : '' });
                    this.props.setAlertMessage(true, translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.max_count_addable_ingredients_reached ? Utils.translate(translations.translations.pages.products.max_count_addable_ingredients_reached, { value: this.props.currentProduct.max_count_addable_ingredients }) : '');
                } else {
                    // Real add
                    let st = this.state;
                    st[ingridientState].push(parseInt(ingridient.ingredientId))
                    this.setState({ [ingridientState]: this.state[ingridientState] })
                }
            }
        }
    }

    ingredientsSortFunc = (a, b) => {
        let priorityOne = a.ingredient.priority * 1;
        let priorityTwo = b.ingredient.priority * 1;
        return (priorityOne < priorityTwo) ? -1 : ((priorityOne > priorityTwo) ? 1 : 0);
    }

    render() {
        const { classes, settings, translations, currentProduct, currLang, selectedCat, categories_hierarchy, view_product_page_id } = this.props;
        let { id, name, product_variants, product_ingredients, product_images, description, allergens, subcategory, in_stock, label, hide_for_ordering, discount_percent, discount_money } = currentProduct;
        name = currentProduct && currentProduct.translations && currentProduct.translations[currLang] && currentProduct.translations[currLang].name ? currentProduct.translations[currLang].name : name;
        description = currentProduct && currentProduct.translations && currentProduct.translations[currLang] && currentProduct.translations[currLang].description ? currentProduct.translations[currLang].description : description;

        let prodImgPath = '';
        if (product_images && typeof product_images[0] !== "undefined" && typeof product_images[0].path !== "undefined" && product_images[0].path) {
            prodImgPath = Utils.getProductImagePath(product_images[0].path);
        }

        if (!product_variants) {
            product_variants = [];
        }
        if (!product_ingredients) {
            product_ingredients = [];
        }

        let currentProductCats = [];
        if (currentProduct && currentProduct.catHierarchyIds) {
            currentProductCats = currentProduct.catHierarchyIds;
        }
        let order_allowed = Utils.checkProductAllowedOrdering(currentProductCats, categories_hierarchy);


        let addableProductIngridients = [];
        let removableProductIngridients = [];
        let requiredProductIngridients = [];
        if (product_ingredients.length > 0 && this.props.productIngredients && Object.values(this.props.productIngredients).length > 0) {
            addableProductIngridients = product_ingredients.filter(pi => pi.type == 'addable' && this.props.productIngredients[pi.ingredientId] && this.props.productIngredients[pi.ingredientId].active == true);
            removableProductIngridients = product_ingredients.filter(pi => pi.type == 'removable' && this.props.productIngredients[pi.ingredientId] && this.props.productIngredients[pi.ingredientId].active == true);
            requiredProductIngridients = product_ingredients.filter(pi => pi.type == 'required' && this.props.productIngredients[pi.ingredientId] && this.props.productIngredients[pi.ingredientId].active == true);
        }

        var spotBrowseOnly = false;
        if (Auth.getTokenOrderType() || this.props.chosenPlaceOrderTypes.length == 1) {
            if (Auth.getTokenOrderType()) {
                var type = this.props.chosenPlaceOrderTypes.find(ot => ot.id === Auth.getTokenOrderType())

                if (type && type.type) {
                    type = type.type;
                }
                // FIXED? Potential bug - once it crashed because of .type not found
                // console.log('type', type);

                if (type == "spot_browse" || type == 'tablet_mode') {
                    spotBrowseOnly = true;
                }
            } else {
                if (this.props.chosenPlaceOrderTypes.length == 1) {
                    if (this.props.chosenPlaceOrderTypes && this.props.chosenPlaceOrderTypes[0] && this.props.chosenPlaceOrderTypes[0].type && (this.props.chosenPlaceOrderTypes[0].type == 'spot_browse' || this.props.chosenPlaceOrderTypes[0].type == 'tablet_mode')) {
                        spotBrowseOnly = true;
                    }
                }
            }
        }

        // console.log('Utils.getSettingsValue(add_to_cart_view_page_button_position) ', Utils.getSettingsValue(settings, 'add_to_cart_view_page_button_position')) )
        return (
            <Fade in={view_product_page_id != 0}>
                <div className={classes.viewProductContainer} style={{ display: (!(view_product_page_id != 0) ? 'none' : 'block') }} data-class="view-product-page" >

                    <div className={classes.productInfoContainer}>
                        <div className={classes.productImageContainer}
                            onClick={() => prodImgPath ? this.setState({ photoOpened: true }) : void (0)}
                            style={{
                                cursor: prodImgPath ? 'pointer' : 'initial',
                                backgroundImage: 'url(' + prodImgPath + ')',
                                minHeight: prodImgPath ? 300 : 0,
                            }} data-class="view-product-page-image">
                            {/* <img className={classes.productImage} src={prodImgPath} /> */}

                            {this.state.photoOpened && (
                                <Lightbox
                                    imageTitle={name}
                                    imageCaption={description && description.length > 0 ? description.toString().replace(/<[^>]*>/g, '') : ''}
                                    mainSrc={[prodImgPath]}
                                    onCloseRequest={() => this.setState({ photoOpened: false })}
                                />
                            )}

                        </div >
                        <div className={classes.productTextContainer}>
                            <div>
                                <div className={classes.productNameContainer} data-class="product-view-page-title"
                                    dangerouslySetInnerHTML={{
                                        __html: name
                                    }}></div>

                                {description && description.length > 0 ?
                                    <React.Fragment>
                                        <br />
                                        <div className={classes.productDescriptionContainer} data-class="product-view-page-description"
                                            dangerouslySetInnerHTML={{
                                                __html: description
                                            }}></div>
                                    </React.Fragment>
                                    :
                                    null
                                }

                                <div className={classes.ProductVariantsContainer} data-class="product-view-page-variants">

                                    <Collapse in={this.state.viewPageError} timeout="auto" unmountOnExit>
                                        <p className={classes.errorContainer}>{this.state.viewPageError}</p>
                                    </Collapse>

                                    {/* Product variants */}
                                    {product_variants.length > 1 ?
                                        <React.Fragment>
                                            <p><b>{translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.choose_size ? translations.translations.pages.products.choose_size : ''}:</b></p>
                                        </React.Fragment>
                                        :
                                        null
                                    }

                                    {product_variants.map((variant, k) => {
                                        let calculatedProductPrice = Utils.calculateProductPriceListingProducts(variant.price, discount_percent, discount_money, settings);

                                        return (
                                            <ListItem button className={this.state.productActiveVariantId == variant.id ? classes.activeProductVariant : {}} key={k} onClick={() => this.setState({ productActiveVariantId: variant.id })} data-class="product-variant-button-viewpage" >
                                                <Checkbox className={classes.variantCheckbox} checked={this.state.productActiveVariantId == variant.id} data-class="variant-checkbox" />
                                                <Grid item xs={7} >
                                                    {/* {variant.size} */}
                                                    {variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}
                                                </Grid>
                                                <Grid item xs={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                    {(discount_percent && parseFloat(discount_percent) != 0) || (discount_money && parseFloat(discount_money) != 0 & Utils.getSettingsValue(settings, 'calculate_product_discount_money_only_for_preorder_orders') != 1) ?
                                                        <React.Fragment>
                                                            <span className="strikethrough"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                        </React.Fragment>
                                                        :
                                                        null
                                                    }

                                                    {calculatedProductPrice.includes('.') ?
                                                        <React.Fragment>
                                                            {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                        </React.Fragment>
                                                        :
                                                        calculatedProductPrice
                                                    }

                                                    {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : ' лв.'}
                                                </Grid>
                                            </ListItem>
                                        )
                                    })}
                                </div>

                                {/* <div className={classes.addToCartButton}>Добави в количката</div> */}

                                {Utils.getSetting('hide_add_to_cart_btn') == 1 || (Utils.getSetting('hide_add_to_cart_btn') == 2 && spotBrowseOnly == 1) ?
                                    null
                                    :
                                    <>
                                        {product_variants.length > 0 && order_allowed && (Utils.getSettingsValue(settings, 'add_to_cart_view_page_button_position') == 'top' || Utils.getSettingsValue(settings, 'add_to_cart_view_page_button_position') == 'both') ?
                                            <Button className={classes.addToCartButton} onClick={() => this.addToCartBtnClicked(this.props.view_product_page_id, this.state.productActiveVariantId)} data-class="view-page-add-to-cart">
                                                <AddShoppingCartIcon style={{ verticalAlign: 'middle' }} />&nbsp;
                                                {Auth.isTabletMode() ?
                                                    (translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.save_for_order ? translations.translations.pages.products.save_for_order : '')
                                                    :
                                                    (translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.add_to_cart ? translations.translations.pages.products.add_to_cart : '')
                                                }
                                            </Button>
                                            :
                                            null
                                        }
                                    </>
                                }
                                {!order_allowed ? <div className={classes.productSubContainer}>{translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.order_disabled ? translations.translations.pages.products.order_disabled : ''}</div> : null}

                                <div className={classes.ProductIngridientsContainer}>

                                    {/* {requiredProductIngridients.length > 1 || addableProductIngridients.length > 0 || removableProductIngridients.length > 0 ?
                                        <>
                                            <hr data-class="separating-line-view-product-page" />
                                            <br />
                                        </>
                                        :
                                        null
                                    } */}

                                    {requiredProductIngridients.length > 1 ?
                                        <React.Fragment>
                                            <p data-class="product-ingridients-container-title" className={classes.productIngredientsContainerTitle}>
                                                {Utils.getSettingsValue(settings, 'required_ingredients_title') ?
                                                    Utils.getSettingsValue(settings, 'required_ingredients_title')
                                                    :
                                                    (translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.additional_options ? translations.translations.pages.products.additional_options : '')
                                                }:
                                            </p>
                                        </React.Fragment>
                                        :
                                        null
                                    }

                                    {requiredProductIngridients.length > 1 ?

                                        <FormControl component="fieldset">
                                            <RadioGroup row className={classes.groupContainer}
                                                value={parseInt(this.state.selectedRequiredProductIngredient) > 0 ? this.state.selectedRequiredProductIngredient : (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId ? requiredProductIngridients[0].ingredientId : '')}
                                                onChange={(e) => this.setState({ selectedRequiredProductIngredient: parseInt(e.target.value) })} >
                                                {requiredProductIngridients.map((ingridient, k) => {
                                                    let produtIngridientName = this.props.productIngredients && this.props.productIngredients[ingridient.ingredientId] && this.props.productIngredients[ingridient.ingredientId].name ? this.props.productIngredients[ingridient.ingredientId].name : '';

                                                    if (this.props.productIngredients && this.props.productIngredients[ingridient.ingredientId] && this.props.productIngredients[ingridient.ingredientId].price && this.props.productIngredients[ingridient.ingredientId].price != 0) {
                                                        produtIngridientName += ' ( + ' + this.props.productIngredients[ingridient.ingredientId].price + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : ' лв.') + ')';
                                                    }
                                                    return (
                                                        <FormControlLabel
                                                            value={ingridient.ingredientId}
                                                            control={
                                                                <Radio
                                                                    className={classes.requiredIngredientsRadio}
                                                                    data-class="required-each-ingredient"
                                                                />
                                                            }
                                                            label={produtIngridientName}
                                                            data-class="required-ingredients-container"
                                                        />
                                                    )
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                        :
                                        null
                                    }

                                    {addableProductIngridients.length > 0 ?
                                        <React.Fragment>
                                            <p data-class="product-ingridients-container-title" className={classes.productIngredientsContainerTitle}>
                                                {Utils.getSettingsValue(settings, 'addable_ingredients_title') ?
                                                    Utils.getSettingsValue(settings, 'addable_ingredients_title')
                                                    :
                                                    (translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.addable_options ? translations.translations.pages.products.addable_options : '')
                                                }:
                                            </p>
                                        </React.Fragment>
                                        :
                                        null
                                    }

                                    {addableProductIngridients.length > 0 ?
                                        <FormControl component="fieldset" >
                                            <FormGroup row className={classes.groupContainer}>
                                                {addableProductIngridients.sort(this.ingredientsSortFunc).map((ingridient, k) =>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox className={classes.addableIngredientsCheckbox}
                                                                checked={this.checkedIngredient(ingridient.type, ingridient.ingredientId)}
                                                                onChange={(e) => this.handleIngridientChangeObject(e, ingridient)}
                                                                data-class="addable-each-ingredient"
                                                            />
                                                        }
                                                        label={this.props.productIngredients && this.props.productIngredients[ingridient.ingredientId] && this.props.productIngredients[ingridient.ingredientId].name ? (this.props.productIngredients[ingridient.ingredientId].name + ' ( + ' + this.props.productIngredients[ingridient.ingredientId].price + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : ' лв.') + ')') : ''}
                                                        data-class="addable-ingredients-container"
                                                    />
                                                )}
                                            </FormGroup>
                                        </FormControl>
                                        :
                                        null
                                    }


                                    {removableProductIngridients.length > 0 ?
                                        <React.Fragment>
                                            <p data-class="product-ingridients-container-title" className={classes.productIngredientsContainerTitle}>

                                                {Utils.getSettingsValue(settings, 'removeable_ingredients_title') ?
                                                    Utils.getSettingsValue(settings, 'removeable_ingredients_title')
                                                    :
                                                    (translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.removable_options ? translations.translations.pages.products.removable_options : '')
                                                }:
                                            </p>
                                        </React.Fragment>
                                        :
                                        null
                                    }

                                    {removableProductIngridients.length > 0 ?
                                        <FormControl component="fieldset" >
                                            <FormGroup row className={classes.groupContainer}>
                                                {removableProductIngridients.sort(this.ingredientsSortFunc).map((ingridient, k) =>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox className={classes.removableIngredientsCheckbox}
                                                                // checked={this.checkedIngredient(ingridient.type, ingridient.ingredientId)}
                                                                onChange={(e) => this.handleIngridientChangeObject(e, ingridient)}
                                                                data-class="removable-each-ingredient"
                                                            />
                                                        }
                                                        label={this.props.productIngredients && this.props.productIngredients[ingridient.ingredientId] && this.props.productIngredients[ingridient.ingredientId].name ? this.props.productIngredients[ingridient.ingredientId].name : ''}
                                                        data-class="removable-ingredients-container"
                                                    />
                                                )}
                                            </FormGroup>
                                        </FormControl>
                                        :
                                        null
                                    }


                                </div>

                                {product_variants.length > 0 && order_allowed ?
                                    <TextField
                                        className={classes.commentTextField}
                                        onChange={e => this.setState({ productComment: e.target.value })}
                                        label={currentProduct && currentProduct.comment_placeholder ? currentProduct.comment_placeholder : translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.comment ? translations.translations.pages.products.comment : ''}
                                        value={this.state.productComment}
                                        multiline
                                        rows="2"
                                        variant="outlined"
                                        data-class="product-view-page-comments-container"
                                    />
                                    :
                                    null
                                }

                                {Utils.getSetting('hide_add_to_cart_btn') == 1 || (Utils.getSetting('hide_add_to_cart_btn') == 2 && spotBrowseOnly == 1) ?
                                    null
                                    :
                                    <>
                                        {product_variants.length > 0 && order_allowed && (Utils.getSettingsValue(settings, 'add_to_cart_view_page_button_position') == 'bottom' || Utils.getSettingsValue(settings, 'add_to_cart_view_page_button_position') == 'both') ?
                                            <Button className={classes.addToCartButton} onClick={() => this.addToCartBtnClicked(this.props.view_product_page_id, this.state.productActiveVariantId)} data-class="view-page-add-to-cart">
                                                <AddShoppingCartIcon style={{ verticalAlign: 'middle' }} />&nbsp;{translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.add_to_cart ? translations.translations.pages.products.add_to_cart : ''}
                                            </Button>
                                            :
                                            null
                                        }
                                    </>
                                }

                                {allergens && Object.values(allergens).length > 0 ?
                                    <div className={classes.allergensContainer} data-class="product-view-page-allergens">
                                        <br />
                                        <b>{translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.alergens ? translations.translations.pages.products.alergens : ''}:</b>
                                        <br />
                                        {Object.values(allergens).map(al => (
                                            <ListItemText primary={al.name} />
                                        ))}
                                    </div>
                                    :
                                    null
                                }

                            </div>
                        </div >


                        {/* <MetaAndOg subcategory={subcategoryName} image={Utils.getSubcategoryImagePath(subcategoryIcon)} />

                        {this.designVariantButtons()}

                        <Grid
                            className={classes.productsContainer}
                            container
                            direction="row"
                            //   justify="space-between"
                            //   justify="center"
                            // alignItems="center"
                            spacing={3}
                        >

                            {subcategory && subcategory.products ?
                                (Object.values(subcategory.products).map((product, k) => (
                                    <ProductListItem
                                        key={k}
                                        k={k}
                                        product={product}
                                        settings={settings}
                                        addProdToCart={this.addProdToCart}
                                        addToCartBtnClicked={this.addToCartBtnClicked}
                                        translations={translations}
                                        currLang={this.props.currLang}
                                    />
                                )))
                                :
                                ''
                            }

                        </Grid>

                        <ProductVariantsModal
                            open={this.state.variantModalOpen}
                            variantModalWithComment={this.state.variantModalWithComment}
                            product={this.state.selectedProduct}
                            addProdToCart={this.addProdToCart}
                            order_comment={this.props.products.order_comment}
                            setOrderComment={this.props.setOrderComment}
                            closeProductVariantModalHandler={() => this.setState({ variantModalOpen: !this.state.variantModalOpen })}
                            settings={settings}
                            translations={translations}
                            currLang={this.props.currLang}
                        /> */}

                    </div>

                    <div className={classes.similarProductsContainer} data-class="similar-products-view-page">{translations && translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.related_products ? translations.translations.pages.products.related_products : ''}</div>

                    <Grid
                        className={classes.productsContainer}
                        container
                        direction="row"
                        //   justify="space-between"
                        //   justify="center"
                        // alignItems="center"
                        spacing={3}
                    >

                        {view_product_page_id != 0 ?
                            ((this.props.similarProducts).map((product, k) => (
                                <ProductListItem
                                    preventGrow={true}
                                    key={1}
                                    k={1}
                                    scrollToTopAfterClick={true}
                                    product={product}
                                    settings={settings}
                                    // addProdToCart={() => void (0)}
                                    // addToCartBtnClicked={() => void (0)}
                                    addProdToCart={this.addProdToCart}
                                    addToCartBtnClicked={(a, b) => this.addToCartBtnClicked(a, b, false)}
                                    translations={translations}
                                    currLang={this.props.currLang}
                                    // setCommentModalProps={(commentModalTitle, commentVariantId) => { this.setState({ openCommentModal: true, commentModalTitle: commentModalTitle, commentVariantId: commentVariantId }) }}
                                    setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}
                                    viewProductPage={true}
                                />
                            )))
                            :
                            ''
                        }

                    </Grid>


                    {Utils.getSettingsValue(settings, 'show_footer') != '' ?
                        <ProductsFooterContainer settings={settings} translations={translations} />
                        :
                        null
                    }

                </div>

            </Fade >
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    // ownProps.match.params

    const prodSlug = ownProps && ownProps.match && ownProps.match.params && ownProps.match.params.prodSlug ? ownProps.match.params.prodSlug : '';
    let viewProductPageId = state.products.view_product_page_id || 0;
    //const currentProduct = state.products.view_product_page_id && state.products && state.products.all_products && state.products.all_products[viewProductPageId] ? state.products.all_products[viewProductPageId] : []
    let currentProduct = state.products && state.products.all_products && Object.values(state.products.all_products).find(p => p.slug == prodSlug);
    if (!currentProduct) {
        currentProduct = {};
    }
    let similarProducts = [];

    var catsHierarchy = state.categories.categories_hierarchy || [];
    let catSlug = ownProps.match.params.catSlug !== "null" ? ownProps.match.params.catSlug : "";
    let selectedCat = catsHierarchy && Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] ? Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] : null;

    // KUM MOMENTA TOVA AKO E 1 GENERIRA 4 RANDOM PRODUKTA KOITO SA HRANI BEZ ZNACHENIE TEKUSHTIQ PRODUKT DALI E HRANA ILI NE, AKO E 0 POKAZVA 1-VITE 4 PROD. OT SUOTVETNATA CAT
    if ((state.settings.settings && state.settings.settings.show_liquid_on_non_liquid_view_product_page && state.settings.settings.show_liquid_on_non_liquid_view_product_page.value && state.settings.settings.show_liquid_on_non_liquid_view_product_page.value == '1')) {
        let simProducts = []
        // if (currentProduct.isLiquid == 0) {
        //     simProducts = state.products.view_product_page_id ? Object.values(state.products.all_products).filter(p => p.isLiquid == 1) : []
        // } else {
        simProducts = state.products.view_product_page_id ? Object.values(state.products.all_products).filter(p => p.isLiquid == 0) : []
        // }

        if (simProducts) {

            const randomStartProduct = getRandomInt(simProducts.length > 4 ? simProducts.length - 4 : 0);

            const randomStartProductFirst = getRandomInt(simProducts.length > 4 ? simProducts.length - 4 : 0);
            const randomStartProductSecond = getRandomInt(simProducts.length > 4 ? simProducts.length - 4 : 0);
            const randomStartProductThird = getRandomInt(simProducts.length > 4 ? simProducts.length - 4 : 0);
            const randomStartProductFour = getRandomInt(simProducts.length > 4 ? simProducts.length - 4 : 0);
            // similarProducts = simProducts.slice(randomStartProduct, randomStartProduct+4)

            similarProducts.push(simProducts.slice(randomStartProductFirst, randomStartProductFirst + 1)[0]);
            similarProducts.push(simProducts.slice(randomStartProductSecond, randomStartProductSecond + 1)[0]);
            similarProducts.push(simProducts.slice(randomStartProductThird, randomStartProductThird + 1)[0]);
            similarProducts.push(simProducts.slice(randomStartProductFour, randomStartProductFour + 1)[0]);
        }

        function getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        }

    } else {
        similarProducts = state.products.view_product_page_id ? Object.values(state.products.all_products).filter(p => p.catHierarchyIds.includes(currentProduct.catHierarchyId)).slice(0, 4) : []
        if (similarProducts.length == 0) {
            similarProducts = state.products.view_product_page_id ? Object.values(state.products.all_products).slice(0, 4) : []
        }
    }
    // console.log('state', state)
    // console.log('similarProducts', similarProducts)

    let chosenPlaceOrderTypes = [];
    let chosenPlaceId = state.places.chosen_place;
    let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
    if (chosenPlace.length == 0) {
        chosenPlace = null;
    } else {
        chosenPlace = chosenPlace[0];
        chosenPlaceOrderTypes = chosenPlace.ordertypes;
    }

    return {
        categories_hierarchy: state.categories.categories_hierarchy || {},
        currentProdSlug: prodSlug,
        subcategory: state.products.subcat_products || null,
        selectedCat: selectedCat || null,
        isLoading: state.products.isLoading || false,
        products: state.products || [],
        categoriesLoaded: state.categories.categories_loaded || 0,
        settings: state.settings.settings || [],
        translations: state.lang,
        currLang: state.lang.currLang,
        view_product_page_id: viewProductPageId,
        currentProduct: currentProduct,
        similarProducts: similarProducts,
        productIngredients: state.products.productIngredients || [],
        chosenPlaceOrderTypes: chosenPlaceOrderTypes,

    };
};

export default withStyles(styles)(connect(mapStateToProps, { getProductsBySubcat, showHeaderCategory, addProductTooltip, addToCart, changeSelectedCartTab, setOrderComment, setMainBackground, removeProductPageId, setProductPageId, setCommentModalData, getProductIngredients, setScrollData, setAlertMessage })(ViewProductPage));