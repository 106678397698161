import { GET_GENERAL_PROMOTIONS } from '../actions/types';

const initialState = {
    general_promotions: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_GENERAL_PROMOTIONS:
            return {
                ...state,
                general_promotions: action.payload
            }
        default:
            return state;
    }
}