let currentDomain = window.location.origin + '/';
let apiDomain = currentDomain;

if (window.location.hostname === 'localhost') {
    apiDomain = 'http://localhost:4000/'; // Custom api requests url
}

export const SITE = currentDomain.replace('http', 'https');
export const API_URL = apiDomain + "api/";

// Image
export const IMG_PATH = currentDomain;
export const PRODUCT_IMG_URL = IMG_PATH + "files/images/";
export const PRODUCT_THUMB_IMG_URL = IMG_PATH + "files/images_thumbs/";
export const SETTINGS_IMG_URL = IMG_PATH + "files/images/";
export const DEFAULT_PRODUCT_IMAGE = 'https://tabl.bg/img/default-product-image.svg';
export const DEFAULT_PRODUCT_THUMB_IMAGE = DEFAULT_PRODUCT_IMAGE; // Temporary
export const PLACE_IMG_URL = currentDomain + "files/places/";
export const VIDEOS_URL = apiDomain + "files/videos/";

export const IMAGE_CAT_IMAGES_PATH = IMG_PATH + "files/category_menu_images/";

export const DEFAULT_SUBCATEGORY_IMAGE = 'https://tabl.bg/img/food/'+(Math.floor(Math.random() * 40) + 1)+'.png'; //'http://lorempixel.com/400/200/food/SERVER-TEST-IMAGE/'; //'https://tabl.bg/img/default-subcategory-image.jpg';
export const DEFAULT_CATEGORY_IMAGE = 'https://tabl.bg/img/default-category-image.svg';
export const DEFAULT_USER_ICON = 'https://tabl.bg/img/default-user-icon.svg'; //feedback

// Settings
export const SCAN_QRCODE_REQUIRED = false;
export const PREORDER_ALLOWED = true;
export const ORDER_EXPIRE_TIME_HOURS = 2;
export const TABLET_ORDER_EXPIRE_TIME_HOURS = 8760; // 1 year
export const KIOSK_ORDER_EXPIRE_TIME_HOURS = 43800; // 5 years
export const BILL_WANTED_EXPIRE_TIME_MINUTES = 15;
export const DEMO = (window.location.hostname == 'demo.tabl.bg' || window.location.hostname == 'test.tabl.bg' || window.location.hostname == 'localhost'); //true;

export const TABLET_MODE_URL = 'tablet-mode';


export const RESERVATION_DEFAULT_PHONE_NUM = '088 864 2555';
export const RESERVATION_RESERVATION_DURATION = 3;
export const RESERVATION_ALLOW_RESERVATIONS_X_DAYS_FROM_NOW = 60;
export const RESERVATION_START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY = 18;
export const RESERVATION_MAX_RESERVATION_TIME_FOR_DINNER_FOR_TODAY = 18;
export const RESERVATION_MAX_RESERVATION_COUNT_PEOPLE = 10;
export const RESERVATION_ALLOW_RESERVATIONS_X_HOURS_BEFORE_PLACE_IS_CLOSED = 2;

export const GOOGLE_MAPS_API_KEY = 'AIzaSyAvKnQrp0hlgsTNclWEondG9A5uzK53kg8';


export const TABL_USER_ADDRESSES_KEY = 'tabl_user_addresses'    // for local storage