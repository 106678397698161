import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { register } from './serviceWorker';

import { Tracker, TrackerProvider } from 'react-tracker'
import { clearCartLocal } from './products/actions/products.action';

import App from './App';
import store from './store';
import history from './utils/history';
import Cookies from 'universal-cookie';

// import './tracking/trackTimeSpentOnPage';
import setCustomPlaceSlugHeader from './utils/setCustomPlaceSlugHeader';

import tracker from './tracking/configureTracker';
// const tracker = new Tracker();
const cookies = new Cookies();

// setup();
// if(window.location.pathname) {
//     let placeSlug = window.location.pathname.split("/")[1];
//     if(placeSlug) {
//         setCustomPlaceSlugHeader(placeSlug);
//     }
// }
// if (Auth.getUserToken()) {
    // verifyToken(Utils.getUserToken())
    // .then((res) => {
    // })
    // .catch(e => {
    // })
// }
//


const app =
    <Provider store={store}>
        <BrowserRouter history={history}>
            <TrackerProvider tracker={tracker}>
                <App />
            </TrackerProvider>
        </BrowserRouter>
    </Provider>

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// register();
