import axios from 'axios';

export default function setCustomClientHeader(clientId) {
    if (clientId) {
        // console.log("SETTING client HEADER ", clientId)
        axios.defaults.headers.common['client'] = clientId;

        // console.log("TOKENN SETT", token);
        // console.log(axios.defaults.headers)
    } else {
        delete axios.defaults.headers.common['client'];
    }
}