import { merge } from 'lodash';

export const designV3_CommonCss = {
    root: {
        position: 'relative',
        display: 'flex',
        backgroundColor: '#fafafa',
        flexDirection: 'column-reverse',
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
    },
    outOfStockCard: {
        opacity: .7,
        pointerEvents: 'none',
    },
    cardWithButtonsInVariant: {
        minWidth: 310,
        margin: '0 !important'
    },
    subcategoryName: {
        position: 'absolute',
        top: 7,
        left: 7,
        zIndex: 3,
        padding: "5px 12px 3px",
        borderRadius: 12,
        backgroundColor: '#151515',
        color: '#fff',
        fontWeight: 700,
        display: 'inline-block',
        fontFamily: 'Gt eesti text,sans-serif',
        fontSize: 11,
        letterSpacing: 1,
        textTransform: 'uppercase',
    },
    labelName: {
        position: 'absolute',
        top: 7,
        right: 7,
        zIndex: 3,
        padding: "5px 12px 3px",
        borderRadius: 12,
        backgroundColor: '#151515',
        color: '#fff',
        fontWeight: 700,
        display: 'inline-block',
        fontFamily: 'Gt eesti text,sans-serif',
        fontSize: 11,
        letterSpacing: 1,
        textTransform: 'uppercase',
    },
    outOfStockLabel: {
        position: 'absolute',
        top: 7,
        left: 7,
        zIndex: 3,
        padding: "5px 12px 3px",
        borderRadius: 12,
        backgroundColor: '#151515',
        color: '#fff',
        fontWeight: 700,
        display: 'inline-block',
        fontFamily: 'Gt eesti text,sans-serif',
        fontSize: 11,
        letterSpacing: 1,
        textTransform: 'uppercase',
    },
    content: {
        padding: 10,
        position: 'relative',
    },
    cover: {
        width: '100%',
        height: 200,
        boxShadow: 'inset 0px -7px 7px 0px #fafafa',
        transition: 'all .2s',
        transform: 'scale(1)',
        border: 'none',

        // filter: 'brightness(100%)',
        // transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
        // transformStyle: 'preserve-3d',
        '&:hover': {
            cursor: 'pointer',
            transform: 'scale(1.12)',

            // filter: 'brightness(90%)',
            // transform: 'translate3d(0px, 0px, 0px) scale3d(1.06, 1.06, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            // transformStyle: 'preserve-3d',
        }
    },
    coverContain: {
        width: '100%',
        height: 200,
        boxShadow: 'inset 0px -7px 7px 0px #fafafa',
        backgroundSize: 'contain', //USED only for the "no-product-image.svg" 
    },
    coverInfoProduct: {
        height: 300,
        boxShadow: 'none',
    },
    options: {
    },
    variantsContainer: {
        fontStyle: 'italic',
        '&:first-child': {
            marginTop: 10,
        },
        borderBottom: '1px solid rgba(0,0,0,0.12)', // not used, see in productListItem
        wordBreak: 'break-word',
        display: 'inline-flex',
        fontSize: '0.95rem',
        color: '#6e6e6e',
        width: '100%',
        margin: 5,
        padding: 10,
        paddingTop: 0,
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    variantsContainerClickableVariants: {
        // SAME \
        '&:first-child': {
            marginTop: 10,
        },
        borderBottom: '1px solid rgba(0,0,0,0.12)',
        wordBreak: 'break-word',
        display: 'inline-flex',
        fontSize: '0.95rem',
        color: '#6e6e6e',
        width: '100%',
        margin: 5,
        padding: 10,
        paddingTop: 0,
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        // SAME ^
        borderBottom: '1px solid rgba(231, 94, 0, .8)',
        paddingTop: 7,
    },
    variantsContainerMultipleButtons: {
        margin: '0',
        marginTop: 5,
        fontStyle: 'normal',
        textAlign: 'center',
    },
    productName: {
        lineHeight: 1.3,
        fontSize: '1.2rem',
        boxShadow: '0px -3px 12px 20px #fafafa',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    productNameInfoProduct: {
        boxShadow: 'none',
    },
    productDescription: {
        lineHeight: 1.2,
        fontSize: '0.9rem',
        paddingTop: 10,
        fontStyle: 'italic',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    variantCommentTextfield: {
        '& > .MuiOutlinedInput-root': {
            width: '100% !important',
            height: '40px !important',
            borderTopLeftRadius: '0px !important',
            borderTopRightRadius: '0px !important',
        }
    },
    // Each variant container
    variantColumnWithOrderButtonContainer: {
        margin: 0,
    },
    variantColumnWithOrderButtonContainerFirstChild: {
        marginBottom: 7
    },
    // Variant price
    variantColumnWithOrderButtonVariantContainer: {
        '@media screen and (max-width: 350px), (min-width: 600px) and (max-width: 700px), (min-width: 960px) and (max-width: 1050px), (min-width: 1280px) and (max-width: 1400px)': {
            '&': {
                fontSize: 13
            }
        },
    },
    // Variant size
    variantColumnWithOrderButtonPriceContainer: {
        marginTop: -3,
        '@media screen and (max-width: 350px), (min-width: 600px) and (max-width: 700px), (min-width: 960px) and (max-width: 1050px), (min-width: 1280px) and (max-width: 1400px)': {
            '&': {
                fontSize: 13
            }
        },
    },

    // Variant comment
    addCommentVariantOrderButton: {
        width: '95%',
        background: '#dfdfdf',
        borderRadius: 5,
        padding: '6px 8px',
        '&:hover': {
            background: '#d5d5d5',
        },
        '@media screen and (min-width: 350px) and (max-width: 380px) , (min-width: 700px) and (max-width: 770px), (min-width: 1050px) and (max-width: 1150px), (min-width: 1400px) and (max-width: 1540px)': {
            '&': {
                fontSize: 13,
                width: '93%',
                padding: '6px 5px',
            }
        },
        '@media screen and (max-width: 350px), (min-width: 600px) and (max-width: 700px), (min-width: 960px) and (max-width: 1050px), (min-width: 1280px) and (max-width: 1400px)': {
            fontSize: 13,
            padding: '3px 0px'
        }
    },

    // Variant add to cart
    addToCartVariantOrderButton: {
        width: '95%',
        background: 'rgb(217, 89, 0)',
        borderRadius: 5,
        padding: '6px 8px',
        color: 'white',
        '&:hover': {
            background: 'rgb(204, 84, 0)',
        },
        '@media screen and (min-width: 350px) and (max-width: 380px) , (min-width: 700px) and (max-width: 770px), (min-width: 1050px) and (max-width: 1150px), (min-width: 1400px) and (max-width: 1540px)': {
            '&': {
                fontSize: 13,
                padding: '6px 5px',
            }
        },
        '@media screen and (max-width: 350px), (min-width: 600px) and (max-width: 700px), (min-width: 960px) and (max-width: 1050px), (min-width: 1280px) and (max-width: 1400px)': {
            fontSize: 13,
            padding: '3px 0px'
        }
    },
}

const designV3_1_2_CommonCss = {
    root: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    addToCartButton: {
        width: '100%',
        marginTop: -6,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 3px 0px rgba(0,0,0,0.12)',
    }
}

export const designV3_1Css = merge({
    addToCartButton: {
        '&:hover': {
            background: '#f86500'
        },
        background: '#e75e00',
        color: 'white',
    }
}, designV3_1_2_CommonCss)

export const designV3_2Css = merge({
    addToCartButton: {
        color: '#e75e00',
    }
}, designV3_1_2_CommonCss)


export const designV3_3Css = {
    addToCartButton: {
        position: 'absolute',
        top: -72,
        right: 10,
        width: 50,
        height: 57,
        borderRadius: 5,
        color: '#e75e00',
        boxShadow: 'inset 0 0 5px #ebebeb',
        background: 'rgba(250, 250, 250, 0.62)',
        border: '1px solid rgba(36, 36, 36, 0.2)',
        zIndex: 1,
        '&:hover': {
            background: 'rgba(250, 250, 250, 0.65)',
            boxShadow: 'inset 0 0 5px #ffffff',
        }
    },
    options: {
        '&:last-child': {
            paddingBottom: 10
        }
    },
    content: {
        '& .MuiButton-endIcon': {
            margin: 0,
        },
    },
    addToCartButtonIcon: {
        fontSize: '37px !important',
    },
    addToCartVariantButtonIcon: {
        fontSize: '30px !important',
    },
    darkerProductContainer: {
        background: '#313131 !important',
    },
    whiterProductContainer: {
        background: '#dfd4c6 !important',
    },
    darkerProductName: {
        boxShadow: 'none !important',
        color: 'white !important'
    },
    darkerProductNameTwo: {
        boxShadow: 'none !important',
        // color: 'white !important'
    },
    darkerProductDescription: {
        color: '#dfdfdf !important',
        '& *': {
            color: '#dfdfdf !important'
        }
    },
    darkerProductVariants: {
        color: 'white !important',
        '& *': {
            color: 'white !important'
        }
    },
    darkerProductImageCover: {
        boxShadow: 'none !important',
    },
    darkerProductAllergens: {
        color: '#dfdfdf !important',
        '& *': {
            color: '#dfdfdf !important'
        }
    }
}