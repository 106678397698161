import {
    SET_SCROLL_DATA, SET_ALERT_MESSAGE, SET_CART_MODAL_OPENED_STATE, SET_TERMS_DIALOG_OPENED, SET_PRIVACY_POLICY_OPENED,
    SET_LINKS_MODAL_OPENED, SET_BOOKING_MODAL_OPENED, SET_DIAL_TOOLTIP_STATE, SET_ERROR_AND_RELOAD_MODAL_MESSAGE,
    SET_BOTTOM_SPEED_DIAL_TOOLTIP_USER_NEEDS_AND_BILL_WANTED_STATE, SET_BOTTOM_SPEED_DIAL_TOOLTIP_STATE, SET_USER_NEEDS_MODAL_STATE,
    SET_GOOGLE_MAPS_DELIVERY_ADDRESS_MODAL_STATE, DISALLOW_ORDERS_FOR_THIS_DEVICE,
    SET_REFUND_DIALOG_OPENED
} from './types';

const initialState = {
    scrollData: null,
    alertMessage: { opened: false, message: '', success: false },
    cartModalOpened: false,
    termsOpened: false,
    refundOpened: false,
    privacyPolicyOpened: false,
    linksModalOpened: false,
    bookingModalOpened: false,
    dial_tooltip_open: false,
    bottom_speed_dial_tooltip_user_needs_and_bill_wanted_open: false,
    bottom_speed_dial_tooltip_open: false,
    errorAndReloadModalMessage: "",
    userNeedsModalOpened: false,
    disallow_orders_for_this_device: false,

    googleMapsDeliveryAddressModalOpened: false,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DIAL_TOOLTIP_STATE:
            return {
                ...state,
                dial_tooltip_open: action.payload
            }
        case SET_BOTTOM_SPEED_DIAL_TOOLTIP_USER_NEEDS_AND_BILL_WANTED_STATE:
            return {
                ...state,
                bottom_speed_dial_tooltip_user_needs_and_bill_wanted_open: action.payload
            }
        case SET_BOTTOM_SPEED_DIAL_TOOLTIP_STATE:
            return {
                ...state,
                bottom_speed_dial_tooltip_open: action.payload
            }
        case SET_USER_NEEDS_MODAL_STATE:
            return {
                ...state,
                userNeedsModalOpened: action.payload,
            }
        case SET_SCROLL_DATA:
            return {
                ...state,
                scrollData: action.payload
            }
        case SET_ALERT_MESSAGE:
            return {
                ...state,
                alertMessage: action.payload
            }
        case SET_CART_MODAL_OPENED_STATE:
            return {
                ...state,
                cartModalOpened: action.payload
            }
        case SET_TERMS_DIALOG_OPENED:
            return {
                ...state,
                termsOpened: action.payload
            }
        case SET_REFUND_DIALOG_OPENED:
            return {
                ...state,
                refundOpened: action.payload
            }
        case SET_PRIVACY_POLICY_OPENED:
            return {
                ...state,
                privacyPolicyOpened: action.payload
            }
        case SET_LINKS_MODAL_OPENED:
            return {
                ...state,
                linksModalOpened: action.payload
            }
        case SET_BOOKING_MODAL_OPENED:
            return {
                ...state,
                bookingModalOpened: action.payload
            }
        case SET_ERROR_AND_RELOAD_MODAL_MESSAGE:
            return {
                ...state,
                errorAndReloadModalMessage: action.payload
            }
        case SET_GOOGLE_MAPS_DELIVERY_ADDRESS_MODAL_STATE:
            return {
                ...state,
                googleMapsDeliveryAddressModalOpened: action.payload
            }
        case DISALLOW_ORDERS_FOR_THIS_DEVICE:
            return {
                ...state,
                disallow_orders_for_this_device: action.payload
            }
        default:
            return state;
    }
}