import axios from 'axios';
import { API_URL } from '../../constants'
import { SET_EPAY_FORM_FIELDS, SET_PAYMENT_STATUS, SET_PAYMENT_KEY, SET_PROCESSING_PAYMENT_MODAL_OPENED} from './types';
import { setCartModalOpenedState } from './../../menu/menu.actions'
import { addErrorMessages, addSuccessMessage } from '../../messages/messages.actions';
import { setCurrentUser, getUserPaymentInstruments } from './../../users/actions/users.actions';
import Auth from '../../utils/Auth';
import jwt from 'jsonwebtoken';


export const submitEpayForm = () => {
    document.querySelector("#epayForm").submit()
}

export const initiateEpayOrder = (orderKey) => dispatch => {

    axios
        .post(API_URL + 'epay/initiateEpayOrder', { orderKey: orderKey }, { timeout: 55000 })
        .then(res => {

            if(res.data.red) {
                window.location = res.data.red;
            }
        }).catch(error => {
            dispatch(addErrorMessages(error))
            dispatch(setProcessingPaymentModalOpened(false));
        });
}

export const initiateEpayOrderOneTouch = (orderKey, payment_instrument) => dispatch => {
    axios
        .post(API_URL + 'epay/initiateEpayOrderOneTouch', { orderKey: orderKey, payment_instrument: payment_instrument}, { timeout: 55000 })
        .then(res => {
            
            if(res.data.success) {
                if(res.data.payload && res.data.payload.payment_status) {
                    dispatch(setPaymentKey(res.data.payload.payment_key));                    
                    dispatch(setPaymentStatus(res.data.payload.payment_status));
                } else {
                    dispatch(addErrorMessages(['Възникна грешка при плащането']))
                    dispatch(setProcessingPaymentModalOpened(false));
                }
            } else {
                dispatch(addErrorMessages(['Възникна грешка при плащането']))
                dispatch(setProcessingPaymentModalOpened(false));
            }

        }).catch(error => {
            // console.log(error);
            dispatch(addErrorMessages(error))
            dispatch(setProcessingPaymentModalOpened(false));
        });
}

export const deleteUserPaymentInstrument = (paymentInstrumentKey) => dispatch => {

    axios
        .post(API_URL + 'epay/deleteUserPaymentInstrument', { key: paymentInstrumentKey }, { timeout: 55000 })
        .then(res => {
            if(res.data && res.data.payload.uToken) {
                Auth.authUser(res.data.payload.uToken);
                dispatch(setCurrentUser(jwt.decode(res.data.payload.uToken)));
            }

            dispatch(getUserPaymentInstruments());
            dispatch(addSuccessMessage("Успешно изтрит инструмент за плащане"))
        }).catch(error => {
            // console.log(error)
            dispatch(addErrorMessages(error))
        });
}

export const setPaymentStatus = (status) => dispatch => { 
    dispatch({
        type: SET_PAYMENT_STATUS,
        payload: status
    })
}

export const setPaymentKey = (key) => dispatch => { 
    dispatch({
        type: SET_PAYMENT_KEY,
        payload: key
    })
}

export const setProcessingPaymentModalOpened = (state) => dispatch => { 
    dispatch({
        type: SET_PROCESSING_PAYMENT_MODAL_OPENED,
        payload: state
    })
}

export const initiateEpayOrderOldEpayFunctionality = (orderKey) => dispatch => {
    // dispatch({ type: LOADING_START });

    // getChecksumAndEncoded()

    axios
        .post(API_URL + 'epay/initiateEpayOrder', { orderKey: orderKey })
        .then(res => {
            // console.log("EPAY INIT RESP ", res);   
            
            if(res.data.encoded && res.data.checksum) {
                // console.log("IMA GI ", res);   

                dispatch({
                    type: SET_EPAY_FORM_FIELDS,
                    payload: { 
                        encoded: res.data.encoded,
                        checksum: res.data.checksum
                    }
                })

                dispatch(submitEpayForm());
            }
            

        }).catch(error => {
            throw (error);
        });
}

export const checkPaymentStatus = (orderKey) => dispatch => {
    return axios
        .post(API_URL + 'epay/checkPaymentStatus', {orderKey: orderKey})
}

export const checkPaymentStatusOneTouch = (paymentKey) => dispatch => {
    return axios
        .post(API_URL + 'epay/checkPaymentStatusOneTouch', {paymentKey: paymentKey})
}

export const getChecksumAndEncoded = (data) => {
    // dispatch({ type: LOADING_START });

    return axios
        .post(API_URL + 'epay/getChecksumAndEncoded', data)
        // .then(res => {
        //     console.log(res);
            
        // }).catch(error => {
        //     throw (error);
        // });
}