import { GET_PLACES, SET_CHOSEN_PLACE, PLACE_IMAGE_UPLOADED, GET_PLACES_FOR_MAP, GET_CITIES_FOR_MAP, GET_TAGS_FOR_MAP } from '../actions/types';

const initialState = {
    places: [],
    chosen_place: 0,
    placeImageUploaded: false,
    places_for_map: [],
    cities: [],
    tags: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PLACES:
            return {
                ...state,
                places: action.payload
            }
        case SET_CHOSEN_PLACE: {
            return {
                ...state,
                chosen_place: action.payload
            }
        }

        case PLACE_IMAGE_UPLOADED:
            return {
                ...state,
                placeImageUploaded: action.payload
            }
        case GET_PLACES_FOR_MAP:
            return {
                ...state,
                places_for_map: action.payload
            }
        case GET_CITIES_FOR_MAP: 
            return {
                ...state,
                cities: action.payload
            }
        case GET_TAGS_FOR_MAP: 
            return {
                ...state,
                tags: action.payload
            }
        default:
            return state;
    }
}