export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_LOGIN_MODAL_OPENED = 'SET_LOGIN_MODAL_OPENED'
export const LOGOUT = 'LOGOUT';
export const SET_REGISTER_MODAL_OPENED = 'SET_REGISTER_MODAL_OPENED';
export const SET_PROFILE_MODAL_OPENED = 'SET_PROFILE_MODAL_OPENED';
export const SET_RESET_PASSWORD_MODAL_OPENED = 'SET_RESET_PASSWORD_MODAL_OPENED';
export const GET_USER_PAYMENT_INSTRUMENTS = 'GET_USER_PAYMENT_INSTRUMENTS';
export const GET_USER_ORDERS = 'GET_USER_ORDERS';
export const SET_WAITER_TABLE_MODAL_OPENED = 'SET_WAITER_TABLE_MODAL_OPENED';
export const GET_TABLES = 'GET_TABLES';
