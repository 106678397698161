import axios from 'axios';
import { GET_REGISTERED_USER_DISCOUNTS } from './types';
import { API_URL } from '../../constants'

export const getRegisteredUserDiscounts = () => dispatch => {
    // console.log("CALL general ")

    axios
        .get(API_URL + 'check-registered-user-discount')
        .then(res => {

            // console.log("general ", res.data)

            return dispatch({
                type: GET_REGISTERED_USER_DISCOUNTS,
                payload: res.data
            })
        }


        ).catch(error => {
            throw (error);
        });
}
