import axios from 'axios';
import {
    SET_VARIANT_MODAL_OPENED, SET_SELECTED_PRODUCT, SET_PROMO_PRODUCT_FOR_CATEGORY_MODAL_OPENED
} from './types';
import { API_URL, DEFAULT_PRODUCT_IMAGE, BILL_WANTED_EXPIRE_TIME_MINUTES } from '../../constants'
import { openScanModal, setCurrOpenedModal, closeScanModal, setOrderModalState } from './../../qr-scan/actions/scan.actions';
// import _ from 'lodash';
import Utils from '../../utils/Utils';
import Auth from '../../utils/Auth';
import history from './../../utils/history';
// import { isEqual, sortBy, difference } from 'lodash';
import { getLocalCart, changeSelectedCartTab, addToCart } from './../../products/actions/products.action';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const TABL_CART_KEY = 'tabl_cart';

export const getCartIdLocal = () => {
    let cart = cookies.get(TABL_CART_KEY);
    if (cart) {
        return cart;
    }
    return null;
}

export const setCartIdLocal = (id) => {
    let config = { path: "/" };
    if (process.env.NODE_ENV === 'production') {
        config.secure = true;
    }
    cookies.set(TABL_CART_KEY, id, config);
}

export const removeCartIdLocal = () => {
    cookies.remove(TABL_CART_KEY, { path: '/' });
}

export const updateRemoteCart = () => dispatch => {
    // console.log("REMOTE ")

    let productsArrToSend = [];
    Object.values(getLocalCart()).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );
    axios
        .post(API_URL + 'cart', {
            cartId: getCartIdLocal(),
            products: productsArrToSend,
        }) // Timeout 35 sec
        .then(res => {
            // console.log("RES " , res)

            if (res.data.cartId) {
                setCartIdLocal(res.data.cartId);
            }

        }).catch(error => {

        });
}

export const addProdToCart = (product_id, product_variant_id, product, productIngredients) => dispatch => {
    const requiredProductIngridients = product.product_ingredients.filter(pi => pi.type == 'required' && productIngredients[pi.ingredientId] && productIngredients[pi.ingredientId].active == true);

    // console.log("CART: ADD PROD TO CART")

    let defautRequiredProductIngridient = 0;
    if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
        defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
    }

    const selectedIngredients = {
        required: defautRequiredProductIngridient,
        addable: [],
        removable: [],
    }

    // this.showAddedProductTooltip(product_id, product_variant_id, product, selectedIngredients);
    // this.props.changeSelectedCartTab(0); // select default tab "Cart"
    // this.props.addToCart(product_id, product_variant_id, product, selectedIngredients);

    dispatch(changeSelectedCartTab(0));
    dispatch(addToCart(product_id, product_variant_id, product, selectedIngredients));
    dispatch(setVariantModalOpened(false));

    dispatch(setPromoProductForCategoryModalOpened(false));
};

export const addToCartBtnClicked = (currProduct, productVariantId, settings) => dispatch => {
    if (settings && settings.variant_modal_with_comment && settings.variant_modal_with_comment.value == 1) {
        // this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
        dispatch(setVariantModalOpened(true));
        dispatch(setSelectedProduct(currProduct));
        debugger;

    } else {
        if (currProduct.product_variants) {
            if (currProduct.product_variants.length === 1 || productVariantId > 0) {
                // this.addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct)
                dispatch(addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct));
            } else {
                // Open variant modal
                dispatch(setVariantModalOpened(true));
                dispatch(setSelectedProduct(currProduct));

                // this.setState({ variantModalOpen: true, selectedProduct: currProduct })
            }
        }
    }

}

export const setVariantModalOpened = (opened) => dispatch => {
    dispatch({
        type: SET_VARIANT_MODAL_OPENED,
        payload: opened
    })
}

export const setSelectedProduct = (product) => dispatch => {
    dispatch({
        type: SET_SELECTED_PRODUCT,
        payload: product
    })
}

export const setPromoProductForCategoryModalOpened = (opened) => dispatch => {
    dispatch({
        type: SET_PROMO_PRODUCT_FOR_CATEGORY_MODAL_OPENED,
        payload: opened
    })
}