import { enTranslations } from './translations/en.js';
import { bgTranslations } from './translations/bg.js';
import { esTranslations } from './translations/es.js';

export const translations = {
    en: enTranslations,
    bg: bgTranslations,
    es: esTranslations,
}

