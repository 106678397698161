import { SET_CLIENT } from './types';

const initialState = {
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_CLIENT:
            return action.payload
            
        default:
            return state;
    }
}