import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';

import store from './../store';
import { logTimeSpentOnPage } from './actions/tracking.actions';
import history from './../utils/history';
import Utils from './../utils/Utils';
import { API_URL } from './../constants';
import { verifyOpenedPlace } from './../places/actions/places.actions';
import IdleTimer from 'react-idle-timer'

const MAX_MINS_TO_TRACK = 5;   // on a single page
const SECONDS_TILL_IDLE = 60; 

class TrackTimeSpentOnPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }

        this.timer = null;
        this.timerStart = null;
        this.timeSpentOnPage = 0;
        this.idleTimer = null

        this.isIdle = false;

    }

    componentDidMount() {

        this.timeSpentOnPage = this.getTimeSpentOnPage();

        this.startCounting();


        history.listen(location => {
            // console.log(history);
            if (history.action === 'POP') {
                this.onHistoryAction();
            } else if(history.action === 'PUSH') {
                this.onHistoryAction();
            }
        })

        window.addEventListener('beforeunload', (event) => {
            // console.log(event);
            store.dispatch(verifyOpenedPlace(null, store.getState().places.chosen_place))

            let endTimeMils = new Date().getTime();

            let startTime = new Date(endTimeMils - this.getTimeSpentOnPage())
            let endTime = new Date(endTimeMils);

            let data = {
                startTime: startTime,
                endTime: endTime,
            };


            let trackData = Utils.getDataForTrack();
            // console.log("TRACK DATA ", trackData)
            if(data && trackData) {
                data.catHierarchyId = this.isCatProdPage(trackData.location) && trackData.prev_cat || null;
                data.productId = this.isCatProdPage(trackData.location) && trackData.prev_prod || null;
                data.page = trackData.location || "";
        
                if(this.getTimeSpentOnPage() > 2000) {  // LOG only if user spent more than 1500 milisec on the page
                    
                    let currState = store.getState();

                    let fd = new FormData();
                    fd.append("startTime", startTime);
                    fd.append("endTime", endTime);
                    fd.append("catHierarchyId", trackData.prev_cat);
                    fd.append("productId", trackData.prev_prod || null);
                    fd.append("page", trackData.location || "");
                    fd.append("placeId", currState.places.chosen_place || 0);
        
                    // console.log("UNLOADING ", fd);
                    let resp = navigator.sendBeacon(API_URL + 'logs/spent_time_on_page', (fd));
                } 
            }
        });

    }

    componentWillReceiveProps(nextProps) {
    }


    getTimeSpentOnPage = () => {
        this.timeSpentOnPage = parseInt(sessionStorage.getItem('timeSpentOnPage'));
        this.timeSpentOnPage = isNaN(this.timeSpentOnPage) ? 0 : this.timeSpentOnPage;
        return this.timeSpentOnPage;
    }
    
    startCounting = () => {
        
        sessionStorage.setItem('timeSpentOnPage', 0);
    
        this.timerStart = Date.now();
        this.timer = setInterval(() => {
            // console.log("IS IDLE", isIdle);

            this.timeSpentOnPage = this.getTimeSpentOnPage()+(Date.now()- this.timerStart);
            if(this.isIdle == false && this.timeSpentOnPage / 1000 < MAX_MINS_TO_TRACK * 60) {  // Update only if less than 5 Mins.. 
                sessionStorage.setItem('timeSpentOnPage', this.timeSpentOnPage);
            }
            this.timerStart = parseInt(Date.now());
            // Convert to seconds
            // console.log(parseInt(timeSpentOnPage/1000));
        },1000);
    }

    onHistoryAction = () => {
        // console.log("STORE ", store);
        store.dispatch(verifyOpenedPlace(null, store.getState().places.chosen_place))
    
        let endTimeMils = new Date().getTime();
    
        let startTime = new Date(endTimeMils - this.getTimeSpentOnPage())
        let endTime = new Date(endTimeMils);
    
        // console.log(startTime, endTime, getTimeSpentOnPage());
    
        let data = {
            startTime: startTime,
            endTime: endTime,
            // page: document.referrer,
        };
    
        let trackData = Utils.getDataForTrack();
        // console.log("TRACK DATA ", trackData)
        if(data && trackData) {
            data.catHierarchyId = this.isCatProdPage(trackData.location) && trackData.prev_cat || null;
            data.productId = this.isCatProdPage(trackData.location) && trackData.prev_prod || null;
            data.page = trackData.location || "";
    
            if(this.getTimeSpentOnPage() > 2000) {  // LOG only if user spent more than 1500 milisec on the page
                store.dispatch(logTimeSpentOnPage(data))
            } 
        }
    
        // let fd = new FormData();
        // fd.append("startTime", startTime);
        // fd.append("endTime", endTime);
        // fd.append("catHierarchyId", trackData.prev_cat);
        // fd.append("productId", trackData.prev_prod || null);
        // fd.append("page", trackData.location || "");
    
     
        // console.log("pushing ", history)
    
        // console.log("TIME SPENT ", getTimeSpentOnPage());
    
        let currState = store.getState();
        Utils.setDataForTrack(currState.categories.selected_cat_hierarchy_id, currState.products.view_product_page_id, history.location.pathname);
    
        this.startCounting();
    
    }


    isCatProdPage = (location) => {
        let otherPages = ['feedback', 'about', 'about-tabl'];
    
        let isCatProdPage = true;
        otherPages.map(page => {
            if(location.includes(page)) {
                isCatProdPage = false;
            }
        })
    
        return isCatProdPage;
    }

    handleOnAction = (event) => {
        // console.log('user did something', event)
    }
    
    handleOnActive = (event) => {
        this.isIdle = false;

        // console.log('user is active', event)
        // console.log('time remaining', this.idleTimer.getRemainingTime())
    }
    
    handleOnIdle = (event) => {
        this.isIdle = true;

        // console.log('user is idle', event)
        // console.log('last active', this.idleTimer.getLastActiveTime())
    }

    render() {
        
        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * SECONDS_TILL_IDLE * 1}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        searchText: state.search.search_text,
        allProducts: state.products.all_products,
        products: state.products || [],
        settings: state.settings.settings || [],
        lang: state.lang || [],
        translations: state.lang.translations || [],
        currLang: state.lang.currLang,
        categoriesHierarchy: state.categories.categories_hierarchy || [],
        clientSlug: state.client ? state.client.slug : ""
    };
};

export default (connect(mapStateToProps, {  })(TrackTimeSpentOnPageComponent));
