import { GET_FEEDBACK_QUESTIONS, GET_FEEDBACK_ANSWERS, SEND_FEEDBACK_SUCCESS } from './../actions/types';

const initialState = {
    feedback_questions: [],
    feedback_answers: [],
}

export default function(state = initialState, action) {
    state.errors = [];

    switch(action.type) {
        case GET_FEEDBACK_QUESTIONS:
            return {
                ...state,
                feedback_questions: action.payload,
            }
        case GET_FEEDBACK_ANSWERS:
            return {
                ...state,
                feedback_answers: action.payload,
            }
        case SEND_FEEDBACK_SUCCESS: 
            return {
                ...state,
                message: "Благодаря за обратната връзка :)" //No translation needed
            }
        default:
            return state;
    }
}