import { SET_EPAY_FORM_FIELDS, SET_PAYMENT_STATUS, SET_PAYMENT_KEY, SET_PROCESSING_PAYMENT_MODAL_OPENED } from './types';

const initialState = {
    payment_status: '',
    payment_key: '',
    processingPaymentModalOpened: false,

    checksum: '',
    encoded: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_EPAY_FORM_FIELDS:
            return {
                ...state,
                checksum: action.payload.checksum,
                encoded: action.payload.encoded
            }
        case SET_PAYMENT_STATUS: 
            return {
                ...state,
                payment_status: action.payload,
            }
        case SET_PAYMENT_KEY: 
            return {
                ...state,
                payment_key: action.payload
            }
        case SET_PROCESSING_PAYMENT_MODAL_OPENED: 
            return {
                ...state,
                processingPaymentModalOpened: action.payload
            }
        default:
            return state;
    }
}