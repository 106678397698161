import { GET_FUTURE_ORDERS_COUNT } from '../actions/types';

const initialState = {
    future_orders_count: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FUTURE_ORDERS_COUNT:
            return {
                ...state,
                future_orders_count: action.payload
            }
        default:
            return state;
    }
}