import axios from 'axios';
import { LOG_SPENT_TIME_ON_PAGE } from './types';
import { API_URL } from '../../constants'

export const logTimeSpentOnPage = (data) => dispatch => {
    let query = "";
    // if(place_id != 0) {
    //     query += "?place_id="+place_id;
    // }
    axios
        .post(API_URL + 'logs/spent_time_on_page', data)
        .then(res =>
            dispatch({
                type: LOG_SPENT_TIME_ON_PAGE,
                payload: res.data
            })

        ).catch(error => {
            throw (error);
        });
}


export const makeSystemLog = (action, data) => dispatch => {
    let query = "";
    // if(place_id != 0) {
    //     query += "?place_id="+place_id;
    // }
    axios
        .post(API_URL + 'logs/make-system-log', {'action': action, 'data': data})
        .then(res => {
            // dispatch({
            //     type: LOG_SPENT_TIME_ON_PAGE,
            //     payload: res.data
            // })

        }).catch(error => {
            throw (error);
        });
}