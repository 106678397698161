import React from "react";
import history from './history';
import {
	PRODUCT_IMG_URL, DEFAULT_PRODUCT_IMAGE, DEFAULT_SUBCATEGORY_IMAGE, DEFAULT_CATEGORY_IMAGE, ORDER_EXPIRE_TIME_HOURS, PRODUCT_THUMB_IMG_URL, DEFAULT_PRODUCT_THUMB_IMAGE,
	SETTINGS_IMG_URL, IMAGE_CAT_IMAGES_PATH, PLACE_IMG_URL, TABL_USER_ADDRESSES_KEY, VIDEOS_URL
} from '../constants';

import { sortBy, isEqual } from 'lodash'
import store from './../store';

import { setScrollData } from './../menu/menu.actions';
import Auth from "./Auth";

class Utils {

	static cropMail(email) {
		if (email && (email.indexOf("@") !== -1)) {
			return email.split("@")[0];
		}
		return email;
	}

	static objectToFormData(obj) {
		let formData = new FormData();
		for (var key in obj) {
			formData.append(key, obj[key]);
		}
		return formData;
	}

	static onChange(e) {
		return { [e.target.name]: (e.target.type && e.target.type === 'number') ? parseFloat(e.target.value) : e.target.value }
	}

	static onChangeCheckbox(e) {
		return { [e.target.name]: e.target.checked ? 1 : 0 };
	}

	static truncate(input, maxInputLength = 15, cutFirstXSymbold = 5) {
		if (input && input.length > maxInputLength)
			return input.substring(0, cutFirstXSymbold) + '...';
		else
			return input;
	}

	static appendLeadingZeroes = (n) =>
		(n <= 9 ? "0" + n : n)

	static buildURLQuery = (obj) =>
		Object.entries(obj)
			.map(pair => pair.map(encodeURIComponent).join('='))
			.join('&');

	static changeListDateFormat = (d) => {
		let bgMonths = [];
		bgMonths[0] = 'яну';
		bgMonths[1] = 'фев';
		bgMonths[2] = 'март';
		bgMonths[3] = 'апр';
		bgMonths[4] = 'май';
		bgMonths[5] = 'юни';
		bgMonths[6] = 'юли';
		bgMonths[7] = 'авг';
		bgMonths[8] = 'сеп';
		bgMonths[9] = 'окт';
		bgMonths[10] = 'ное';
		bgMonths[11] = 'дек';

		return this.appendLeadingZeroes(d.getDate()) + '-' + bgMonths[d.getMonth()] + '-' + d.getFullYear() + ' ' + this.appendLeadingZeroes(d.getHours()) + ':' + this.appendLeadingZeroes(d.getMinutes())
	}

	static errorHandler(error) {
		const customErrorMsg = "Възникна грешка #";
		let errorCode = 0;

		// https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
		if (error.response) {
			errorCode = 1;
			console.log(error.response.data); //DO NOT REMOVE
			console.log(error.response.status); //DO NOT REMOVE
			console.log(error.response.headers); //DO NOT REMOVE
			/*
				* The request was made and the server responded with a
				* status code that falls out of the range of 2xx
			*/
		} else if (error.request) {
			errorCode = 2;
			errorCode += ' - Проблем със сървъра или слаб интернет!';
			/*
			* The request was made but no response was received, `error.request`
			* is an instance of XMLHttpRequest in the browser and an instance
			* of http.ClientRequest in Node.js
			*/
			console.log(error.request); //DO NOT REMOVE
		} else {
			errorCode = 3;
			// Something happened in setting up the request and triggered an Error
			console.log('Error', error.message); //DO NOT REMOVE
		}

		return customErrorMsg + errorCode;
	}

	static navigateTo = (url) => {
		history.push(url);
	}


	static getSettingsPath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return SETTINGS_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return SETTINGS_IMG_URL + imgSrc;
		}
		return '';
	}

	static getProductImagePath = (imgSrc, returnDefaultImage = 0) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PRODUCT_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return PRODUCT_IMG_URL + imgSrc;
		}
		if (returnDefaultImage) {
			return DEFAULT_PRODUCT_IMAGE;
		}
		return '';
	}

	static getProductThumbImagePath = (imgSrc, returnDefaultImage = 0) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PRODUCT_THUMB_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return PRODUCT_THUMB_IMG_URL + imgSrc;
		}
		if (returnDefaultImage) {
			return DEFAULT_PRODUCT_THUMB_IMAGE;
		}
		return '';
	}

	static getSubcategoryImagePath = (imgSrc, returnDefaultImage = 0) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PRODUCT_THUMB_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return PRODUCT_THUMB_IMG_URL + imgSrc;
		}
		if (returnDefaultImage) {
			return DEFAULT_SUBCATEGORY_IMAGE;
		}
		return '';
	}


	static getCategoryImagePath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			} else {
				if (window.location.hostname == 'localhost') {
					return PRODUCT_THUMB_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc; //TODO this is not product_img_url
				}
				return PRODUCT_THUMB_IMG_URL + imgSrc; //TODO this is not product_img_url
			}
		}
		return DEFAULT_CATEGORY_IMAGE;
	}


	static getImageCategoryImagePath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			} else {
				if (window.location.hostname == 'localhost') {
					return IMAGE_CAT_IMAGES_PATH.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc; //TODO this is not product_img_url
				}
				return IMAGE_CAT_IMAGES_PATH + imgSrc; //TODO this is not product_img_url
			}
		}
		return DEFAULT_CATEGORY_IMAGE;
	}

	static getPlaceImagePath = (imgSrc, returnDefaultImage = 0) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PLACE_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return PLACE_IMG_URL + imgSrc;
		}
		if (returnDefaultImage) {
			return "https://tabl.bg/img/default-place-image.jpg";
		}

		return '';
	}

	static getCategoryVideoPath = (videoName) => {
		if (videoName) {
			if ((videoName.indexOf('http://') === 0 || videoName.indexOf('https://') === 0)) {
				return videoName;
			}
			if (window.location.hostname == 'localhost') {
				return VIDEOS_URL.replace(window.location.origin, 'https://menu.tabl.bg') + videoName;
			}

			return VIDEOS_URL + "/" + videoName
		}
		return '';
	}

	static checkProductAllowedOrdering = (productCats, allCats) => {
		let productOrderAllowed = false;

		if (productCats && allCats && Object.keys(allCats).length > 0) {
			Object.values(allCats).map(c => {
				if (productCats.includes(c.id)) {
					productOrderAllowed = productOrderAllowed || c.order_allowed
				}
			});
		}

		return productOrderAllowed;
	}


	static normalizePrice = (price) => {
		return price.toFixed(2);
	}



	static calculateAnimationTimeout = (k) => {
		const animTimeout = 450 + (k * 100);
		return (animTimeout > 3000) ? 3000 : animTimeout;
	}



	static fireEvent = (element, event) => {
		// console.log("in Fire Event");
		if (document.createEventObject) {
			// dispatch for IE
			// console.log("in IE FireEvent");
			var evt = document.createEventObject();
			return element.fireEvent('on' + event, evt)
		}
		else {
			// dispatch for firefox + others
			// console.log("In HTML5 dispatchEvent");
			var evt = document.createEvent("HTMLEvents");
			evt.initEvent(event, true, true); // event type,bubbling,cancelable
			return !element.dispatchEvent(evt);
		}
	}

	static getSettingsValue = (settings, settingsName = '', returnDefault = false) => {
		if (settingsName && settings && Object.values(settings).length > 0 && settings[settingsName] && settings[settingsName].value && (settings[settingsName].value).toString().length > 0) {
			return settings[settingsName].value;
		}
		return returnDefault;
	}

	static replaceString = (inputString, stringToBeReplaced, stringForReplace, replaceWithNewLine) => {
		if (replaceWithNewLine) {
			return inputString.split(stringToBeReplaced).flatMap((part, index) =>
				index > 0 ? [<br key={index} />, part] : [part]
			);
		} else {
			return inputString.split(stringToBeReplaced).join(stringForReplace);
		}
	}

	static getSetting = (name) => {
		let currState = store.getState();
		if (currState && currState.settings && currState.settings.settings && currState.settings.settings[name] && currState.settings.settings[name].value) {
			return currState.settings.settings[name].value;
		}

		return null;
	}

	// {this.translate(this.props.translations.common.dashboard, {count: 5, total: 8})}
	static translate(text, args = {}) {
		if (Object.keys(args).length === 0) {
			return text;
		}

		Object.entries(args).map(a => text = text.replace('{' + a[0] + '}', a[1]))
		return text;
	}

	static validatedCommentObject(allComments, variantId, comment) {
		const commentExists = allComments.findIndex(p => parseInt(p.variantId) == parseInt(variantId));
		if (commentExists === -1) {
			if (!comment) {
				return false;
			}

			let currentComment = {}
			currentComment.variantId = variantId;
			currentComment.comment = comment;

			allComments.push(currentComment);
		} else {
			allComments[commentExists].comment = comment;
		}

		return allComments;
	}


	// Categories and Subcategories
	static splitCatSubcatOnTwoRowsBySpace(name, settings) {
		if ((settings && settings.split_header_category_subcategory_on_two_rows && settings.split_header_category_subcategory_on_two_rows.value && settings.split_header_category_subcategory_on_two_rows.value == '1')
			&& (name.indexOf(' ') !== -1)) {
			// Used if two spaces in string // bugfix
			let secondPartsString = '';
			let secondPartArr = (name.split(' ')).slice(1);
			secondPartArr.map(a => secondPartsString += (' ' + a))

			return <React.Fragment>{name.split(' ')[0]}<br />{secondPartsString}</React.Fragment>
		} else if (settings && settings.replace_slash_with_new_line_category_list && settings.replace_slash_with_new_line_category_list.value && settings.replace_slash_with_new_line_category_list.value == '1') {
			return this.replaceString(name, '/', '', 1);
		} else {
			return name;
		}
	}

	// Orders
	// Checks if ordering is enabled, based on settings
	static disabledOrdering(settings, date = new Date()) {

		// Not disabled overtime orders
		if (settings.disable_overtime_orders && settings.disable_overtime_orders.value && settings.disable_overtime_orders.value != 1) {
			return false;
		}

		let passedDayInt = date.getDay();
		if (passedDayInt == 0) {
			passedDayInt = 7
		}

		let working_hours = this.getSettingsValue(settings, 'working_hours')[passedDayInt];

		// If disabled the whole day
		if (!working_hours || working_hours == '-') {
			return true;
		} else {
			let now = new Date();
			const nowHour = now.getHours();
			const nowMinutes = now.getMinutes();
			let nowDayInt = now.getDay();

			if (nowDayInt == 0) {
				nowDayInt = 7
			}

			let working_hours_splited = this.getSettingsValue(settings, 'working_hours')[nowDayInt];
			working_hours_splited = working_hours_splited.split('-');

			let minPickupTime = working_hours_splited[0];
			let minPickupTimeHours = parseInt(minPickupTime.split(':')[0]);
			let minPickupTimeMinutes = parseInt(minPickupTime.split(':')[1]);

			let maxPickupTime = working_hours_splited[1];
			let maxPickupTimeHours = parseInt(maxPickupTime.split(':')[0]);
			let maxPickupTimeMinutes = parseInt(maxPickupTime.split(':')[1]);

			if (nowHour > maxPickupTimeHours || nowHour < minPickupTimeHours) {
				return true;
			} else {
				if (nowHour == minPickupTimeHours && nowMinutes < minPickupTimeMinutes) {
					return true;
				} else if (nowHour == maxPickupTimeHours && nowMinutes > maxPickupTimeMinutes) {
					return true;
				}
			}

			return false;

		}

	}


	// Always prints message, check first if ordering is enabled by calling: this.disabledOrdering
	// If this method is called => orders are disabled for the current time
	static getDisabledOrderingMessage(translations, settings) {
		const currentHour = new Date().getHours();
		const currentMinutes = new Date().getMinutes();

		let currentDayInt = new Date().getDay();
		if (currentDayInt == 0) {
			currentDayInt = 7
		}

		function addDays(date, days) {
			var result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		}

		let working_hours = this.getSettingsValue(settings, 'working_hours')[currentDayInt];

		let nextWorkingDay = this.getSettingsValue(settings, 'working_hours')[currentDayInt];
		let dayInt = currentDayInt;
		let daysAfterCurrent = -1;
		// Find first working day

		for (let i = currentDayInt; i <= currentDayInt + 7; i++) {
			dayInt = i
			if (dayInt > 7) dayInt -= 7;
			daysAfterCurrent++;

			nextWorkingDay = this.getSettingsValue(settings, 'working_hours')[dayInt];

			if (nextWorkingDay == '-') {
				continue;
			} else if (nextWorkingDay) {
				let working_hours_splited = nextWorkingDay.split('-');

				const minPickupTime = working_hours_splited[0];
				const minPickupTimeHours = parseInt(minPickupTime.split(':')[0]);
				const minPickupTimeMinutes = parseInt(minPickupTime.split(':')[1]);

				const maxPickupTime = working_hours_splited[1];
				const maxPickupTimeHours = parseInt(maxPickupTime.split(':')[0]);
				const maxPickupTimeMinutes = parseInt(maxPickupTime.split(':')[1]);

				// Current day 
				if (daysAfterCurrent == 0) {
					if (currentHour < minPickupTimeHours) {
						break;
					} else {
						if (currentHour == minPickupTimeHours && currentMinutes < minPickupTimeMinutes) {
							break;
						} else if (currentHour == maxPickupTimeHours && currentMinutes > maxPickupTimeMinutes) {
							//Do NOT break, Find next working
						}
					}
				} else {
					// Other day (not today) - found hours
					if (nextWorkingDay != '-') {
						break;
					}
				}
			}

		}

		working_hours = nextWorkingDay;

		let nextWorkingDate = addDays(new Date(), daysAfterCurrent);
		let nextWorkingDateDay = nextWorkingDate.getDay();
		if (nextWorkingDateDay == 0) nextWorkingDateDay = 7

		// Disabled without any working hours
		if (nextWorkingDay == '-') {
			return this.translate(translations.pages.cart_delivery.restaurant_closed_label)
		}

		// Disabled with info when will work again
		if (daysAfterCurrent == 0) {
			return this.translate(translations.pages.cart_delivery.restaurant_closed_label_today, { value: nextWorkingDay })
		} else if (daysAfterCurrent == 1) {
			return this.translate(translations.pages.cart_delivery.restaurant_closed_label_tommorow, { value: nextWorkingDay })
		} else {
			return this.translate(translations.pages.cart_delivery.restaurant_closed_label_day, { day: translations.common.date.day[nextWorkingDateDay], value: nextWorkingDay })
		}

	}

	// Product ingredients
	static getCartProductQuantity(product_variant_id, selectedIngredients) {
		var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
		to_order = JSON.parse(to_order);
		let foundSameIngredients = -1;
		let cartProductQuantity = 1;
		// console.log('to_order', to_order)
		if (to_order && Object.values(to_order).length > 0) {
			Object.values(to_order).map(o => {
				// console.log('ooooooooooooooh', o)
				foundSameIngredients = o.find(i => ((i.product_variant_id == product_variant_id) && (parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable)))))));
				if (foundSameIngredients) {
					cartProductQuantity = parseInt(foundSameIngredients.quantity) + 1
				}
			})
		}
		// console.log('cartProductQuantity', cartProductQuantity)
		return cartProductQuantity;
	}


	static differentIngridients(product_variant_id, selectedIngredients) {
		// console.log('product_variant_id', product_variant_id)
		// console.log('selectedIngredients', selectedIngredients)
		var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
		to_order = JSON.parse(to_order);

		let differentIngridients = false;
		if (to_order && Object.values(to_order).length > 0) {
			// console.log('to_order', to_order)
			Object.values(to_order).map(o => {
				// console.log('real o', o)

				// console.log('o.length', o.length)
				// console.log('o.filter(op=>op.product_variant_id == product_variant_id', o.filter(op => op.product_variant_id == product_variant_id));

				if (o.filter(op => op.product_variant_id == product_variant_id).length == 1) {
					// console.log('1 e')
					// console.log('o[0]', o[0])
					// console.log('o[0].ingredients', o[0].ingredients)
					// console.log('parseInt(o[0].product_variant_id) == parseInt(product_variant_id', parseInt(o[0].product_variant_id) == parseInt(product_variant_id));
					// console.log('parseInt(o[0].ingredients.required) == parseInt(selectedIngredients.required)', parseInt(o[0].ingredients.required) == parseInt(selectedIngredients.required))
					// console.log('isEqual(sortBy(o[0].ingredients.addable), sortBy(selectedIngredients.addable))', isEqual(sortBy(o[0].ingredients.addable), sortBy(selectedIngredients.addable)))
					// console.log('isEqual(sortBy(o[0].ingredients.removable), sortBy(selectedIngredients.removable))', isEqual(sortBy(o[0].ingredients.removable), sortBy(selectedIngredients.removable)))

					if (parseInt(o[0].product_variant_id) == parseInt(product_variant_id)
						&&

						(Object.values(o[0].ingredients).length == 0 ||
							(parseInt(o[0].ingredients.required) == parseInt(selectedIngredients.required)
								&& (isEqual(sortBy(o[0].ingredients.addable), sortBy(selectedIngredients.addable)))
								&& (isEqual(sortBy(o[0].ingredients.removable), sortBy(selectedIngredients.removable)))
							)
						)
					) {

						differentIngridients = true;
						// console.log('imago 1')
					}

				} else if (o.filter(op => op.product_variant_id == product_variant_id).length > 1) {
					// console.log('nee 1')
					Object.values(o).map(oo => {
						if (parseInt(oo.product_variant_id) == parseInt(product_variant_id)
							&& parseInt(oo.ingredients.required) == parseInt(selectedIngredients.required)
							&& (isEqual(sortBy(oo.ingredients.addable), sortBy(selectedIngredients.addable)))
							&& (isEqual(sortBy(oo.ingredients.removable), sortBy(selectedIngredients.removable)))
						) {

							differentIngridients = true;
							// console.log('imago 2')
						}
					})
				}

			})
		}
		// console.log('razlichen li e :', !differentIngridients)
		return !differentIngridients;

	}

	static setDataForTrack(cat_id, prod_id, location) {
		let tabl_tracking = {
			"prev_cat": cat_id, //store.getState().categories.selected_cat_hierarchy_id || 0,
			"prev_prod": prod_id, //store.getState().products.view_product_page_id || 0
			"location": location
		}

		window.localStorage.setItem("tabl_tracking", JSON.stringify(tabl_tracking));
	}

	static getDataForTrack() {
		let catAndProd = window.localStorage.getItem("tabl_tracking");

		if (catAndProd) {
			return JSON.parse(catAndProd);
		} else {
			return null;
		}
	}

	static clearDataForTrack() {
		window.localStorage.removeItem("tabl_tracking");
	}

	static sortPriority = (asc = true) => {
		return function (a, b) {
			let priorityOne = a.priority * 1;
			let priorityTwo = b.priority * 1;
			// equal items sort equally
			// if (a === b) {
			//     return 0;
			// }
			// // nulls sort after anything else
			// if (priorityOne === 0) {
			// 	return 1;
			// }
			// else if (priorityTwo === 0) {
			// 	return -1;
			// }
			// // otherwise, if we're ascending, lowest sorts first
			// return priorityOne < priorityTwo ? -1 : 1;

			return (priorityOne < priorityTwo) ? -1 : ((priorityOne > priorityTwo) ? 1 : 0);

		};
	}

	static sortName = (asc = true, column = 'name') => {
		return function (a, b) {
			var nameA = a[column].toUpperCase(); // ignore upper and lowercase
			var nameB = b[column].toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		}
	}


	static normalizeCartProducts = (cartProducts = []) => {
		let newp = [];

		Object.values(cartProducts).map(p => {
			newp = newp.concat(p)
		})

		return newp;

	}


	// Each price for each product - List products
	static calculateProductPriceListingProducts = (productVariantPrice, discountPercent, discountMoney, settings) => {
		let calculatedProductPrice = productVariantPrice;

		if (discountPercent) {
			calculatedProductPrice = (parseFloat(calculatedProductPrice - ((parseFloat(discountPercent) * parseFloat(calculatedProductPrice)) / 100))).toFixed(2);
		}

		if (settings && settings.calculate_product_discount_money_only_for_preorder_orders && settings.calculate_product_discount_money_only_for_preorder_orders.value && settings.calculate_product_discount_money_only_for_preorder_orders.value == 1) {
			// Do not calculate discount money on listing page, if there is a setting to calculate it only for preorder
		} else {
			if (discountMoney) {
				calculatedProductPrice = parseFloat(calculatedProductPrice - parseFloat(discountMoney)).toFixed(2);
			}
		}


		// TODO - add price for default required ingredient

		return calculatedProductPrice;

	}

	// Ingredients
	static getIngredientNamesByIds = (ingredientIds, productIngredients) => {
		// const { productIngredients } = this.props;
		// console.log('checking for: ', ingredientIds)
		let ingredientsNames = '';
		if (productIngredients && Object.values(productIngredients).length) {
			// debugger;
			if (Array.isArray(ingredientIds)) {
				// console.log('arr')
				Object.values(ingredientIds).map((i, k) => {
					// console.log('map: ', i, ' ', k)

					if (productIngredients[i] && productIngredients[i].name) {
						if (k != 0) {
							ingredientsNames += ', ';
						}
						ingredientsNames += productIngredients[i].name;
					}
					// console.log('ingredientsNames: ', ingredientsNames)
				})
			} else {
				// console.log('elsa')
				if (productIngredients && productIngredients[parseInt(ingredientIds)] && productIngredients[parseInt(ingredientIds)].name) {
					ingredientsNames += productIngredients[parseInt(ingredientIds)].name;
				}
				// console.log('ingredientsNames :', ingredientsNames)
			}
		}

		return ingredientsNames;
	}

	static addIngredientsPrice = (item, productIngredients) => {
		let ingredientsPrice = 0;
		// console.log('item INGR', item)
		// console.log('productIngredients', productIngredients)
		// console.log('parseInt(item.ingredients.required)', parseInt(item.ingredients.required))
		// console.log('item.product_ingredients', item.product_ingredients)
		// console.log('productIngredients', productIngredients)
		if (item && item.ingredients && item.product_ingredients && Object.values(item.product_ingredients).length > 0 && productIngredients) {
			if (item.ingredients.required) {
				if (productIngredients && productIngredients[parseInt(item.ingredients.required)] && productIngredients[parseInt(item.ingredients.required)].price) {
					// console.log('adding: productIngredients[parseInt(item.ingredients.required)].price', productIngredients[parseInt(item.ingredients.required)].price)
					ingredientsPrice += parseFloat(productIngredients[parseInt(item.ingredients.required)].price);
				}
			}

			if (item.ingredients.addable) {
				Object.values(item.ingredients.addable).map(ingrId => {
					if (productIngredients && productIngredients[parseInt(ingrId)] && productIngredients[parseInt(ingrId)].price) {
						// console.log('adding: productIngredients[parseInt(ingrId)].price', productIngredients[parseInt(ingrId)].price)
						ingredientsPrice += parseFloat(productIngredients[parseInt(ingrId)].price);
					}
				})
			}

			// if (item.ingredients.removable) {
			//     Object.values(item.ingredients.removable).map(ingrId => {
			//         if (this.props.productIngredients && this.props.productIngredients[parseInt(ingrId)] && this.props.productIngredients[parseInt(ingrId)].price) {
			//             console.log('adding: this.props.productIngredients[parseInt(ingrId)].price', this.props.productIngredients[parseInt(ingrId)].price)
			//             ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ingrId)].price);
			//         }
			//     })
			// }

		}

		return ingredientsPrice;
	}


	// Each price for each product - Cart modal
	/*
		@forceQuantity - Used when you have to know the price of that product, if passed '1' the current product quantity is not valid and not calculated
	*/


	static calculateBoxesForProducts = (products, settings, ordertype, productIngredients, forceQuantity, calculateAdditionalPrice = true) => {
		let totalCartPrice = 0;

		Object.values(products).map((item, k) => {
			const quantity = item.quantity;
			const additional_price = item.additional_price ? item.additional_price : item.product_variant ? item.product_variant.additional_price : 0;

			// Product quantity
			let productQuantity = forceQuantity ? forceQuantity : quantity;
			const calculate_product_box_only_for_preorder_orders = this.getSettingsValue(settings, 'calculate_product_box_only_for_preorder_orders')

			const show_variant_additional_price = this.getSettingsValue(settings, 'show_variant_additional_price');

			// BOX
			if (calculateAdditionalPrice
				&& (show_variant_additional_price == 1)
				&&
				(
					(calculate_product_box_only_for_preorder_orders == 1 && ordertype == 'preorder')
					|| (calculate_product_box_only_for_preorder_orders != 1)
				)

				&& (((parseFloat(additional_price)).toFixed(2)) != '0.00')
			) {
				totalCartPrice += parseFloat(additional_price) * 1 * quantity;
			}

		})
		return parseFloat(totalCartPrice).toFixed(2);
	}



	static calculateOrderDiscountMoneyForProducts = (products, settings, productIngredients, forceQuantity, calculateAdditionalPrice = true) => {
		let totalDiscountMoney = 0;

		Object.values(products).map((item, k) => {
			const quantity = item.quantity;
			// const additional_price = item.additional_price ? item.additional_price : item.product_variant ? item.product_variant.additional_price : 0;
			const discount_money = parseFloat(item.discount_money)

			totalDiscountMoney += parseFloat(discount_money) * parseInt(quantity);

			// // Product quantity
			// let productQuantity = forceQuantity ? forceQuantity : quantity;

			// // BOX
			// if (calculateAdditionalPrice && (settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (settings.calculate_product_box_only_for_preorder_orders && settings.calculate_product_box_only_for_preorder_orders.value && settings.calculate_product_box_only_for_preorder_orders.value == 1) && (((parseFloat(additional_price)).toFixed(2)) != '0.00')) {
			// 	totalCartPrice += parseFloat(additional_price) * 1 * productQuantity;
			// }

		})

		return parseFloat(totalDiscountMoney).toFixed(2);
	}



	static calculateProductPriceOrder = (item, settings, productIngredients, forceQuantity, calculateAdditionalPrice = true, orderType = null) => {
		let totalCartPrice = 0;

		// Cart price structure / Order price structure
		const price = item.price ? item.price : item.product_variant ? item.product_variant.price : 0;
		const discount_percent = item.discount_percent;
		const discount_money = item.discount_money;
		const quantity = item.quantity;
		const additional_price = item.additional_price ? item.additional_price : item.product_variant ? item.product_variant.additional_price : 0;

		//Product discount
		let productPrice;
		const calculate_product_discount_money_only_for_preorder_orders = this.getSettingsValue(settings, 'calculate_product_discount_money_only_for_preorder_orders');
		if (calculate_product_discount_money_only_for_preorder_orders == '1') {
			if (orderType == 'preorder') {
				productPrice = this.calculateProductPriceListingProducts(price, discount_percent, discount_money);
			} else {
				productPrice = this.calculateProductPriceListingProducts(price, discount_percent);
			}
		} else {
			productPrice = this.calculateProductPriceListingProducts(price, discount_percent, discount_money);
		}

		// Product quantity
		let productQuantity = forceQuantity ? forceQuantity : quantity;

		//ingredients
		totalCartPrice += (parseFloat(productPrice) + parseFloat(this.addIngredientsPrice(item, productIngredients))) * 1 * productQuantity;

		// BOX
		if (calculateAdditionalPrice && (settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (settings.calculate_product_box_only_for_preorder_orders && settings.calculate_product_box_only_for_preorder_orders.value && settings.calculate_product_box_only_for_preorder_orders.value != 1) && (((parseFloat(additional_price)).toFixed(2)) != '0.00')) {
			totalCartPrice += parseFloat(additional_price) * 1 * productQuantity;
		}

		return parseFloat(totalCartPrice).toFixed(2);

	}


	// Calculate total price for all products - Cart modal	
	static calculateTotalPriceForOrder = (products, settings, productIngredients, calculateAdditionalPrice = true, orderType) => {
		var totalCartPrice = 0;
		Object.values(products).map((item, k) => {
			totalCartPrice += parseFloat(this.calculateProductPriceOrder(item, settings, productIngredients, 0, calculateAdditionalPrice, orderType));
		})

		return parseFloat(totalCartPrice).toFixed(2);
	}

	static getOrderTypeDiscount = (choosenPlace, orderType, currentCartPrice, returnObjectInfo = false, promocodeData = []) => {
		let now = new Date();
		let currentHour = now.getHours();
		let currentMinutes = now.getMinutes();
		let currentSeconds = now.getSeconds();

		let currentPlaceOrderType = choosenPlace && choosenPlace.ordertypes && choosenPlace.ordertypes.find(t => t.type == orderType);
		let currentPlaceOrderTypeDiscount = currentPlaceOrderType && currentPlaceOrderType.discounts ? currentPlaceOrderType.discounts : [];

		let negative_price = 0;
		let returnObject = {};

		let dateWithHoursForCompare = Date.parse('01/01/2021 ' + currentHour + ':' + currentMinutes + ':' + currentSeconds); // Date does not matter
		currentPlaceOrderTypeDiscount = currentPlaceOrderTypeDiscount.filter(d => Date.parse('01/01/2021 ' + d.active_start_time) <= dateWithHoursForCompare && dateWithHoursForCompare <= Date.parse('01/01/2021 ' + d.active_end_time))

		if (promocodeData && (Object.values(promocodeData).length == 0 || (promocodeData && promocodeData.payload && promocodeData.payload.found == false) || (promocodeData && promocodeData.payload && promocodeData.payload.found && !promocodeData.payload.ignore_other_discounts))) {
			currentPlaceOrderTypeDiscount
				.sort((a, b) => (a.price_over - b.price_over) || (a.value - b.value))
				.map((d, k) => {
					if (currentCartPrice >= d.price_over && (typeof currentPlaceOrderTypeDiscount[k + 1] === "undefined" || (typeof currentPlaceOrderTypeDiscount[k + 1] !== "undefined" && currentCartPrice < currentPlaceOrderTypeDiscount[k + 1].price_over))) {
						returnObject.value_type = d.value_type;
						returnObject.value = d.value;

						if (d.value_type == 'percent') {
							negative_price += (parseFloat(d.value) * parseFloat(currentCartPrice) / 100);
						} else if (d.value_type == 'money') {
							negative_price += parseFloat(d.value);
						}
					}
				})
		}


		if (promocodeData && promocodeData.payload && promocodeData.payload.found && promocodeData.payload.discount_value && promocodeData.payload.discount_type) {
			let discountType = promocodeData.payload.discount_type;
			let discountValue = promocodeData.payload.discount_value;

			if (discountType == 'percent') {
				negative_price += (parseFloat(discountValue) * parseFloat((currentCartPrice - negative_price)) / 100);
			} else if (discountType == 'money') {
				negative_price += parseFloat(discountValue);
			}
		}

		return returnObjectInfo ? returnObject : negative_price;
	}

	static getOrderTypePrice = (choosenPlace, orderType, currentCartPrice, returnObjectInfo) => {
		// Delivery only for now
		// Money only for now value_type
		// Price instead of value
		let currentPlaceOrderType = choosenPlace.ordertypes.find(t => t.type == orderType);
		let currentPlaceOrderTypePrices = currentPlaceOrderType && currentPlaceOrderType.prices ? currentPlaceOrderType.prices : [];
		let positive_price = 0;
		let returnObject = {};


		currentPlaceOrderTypePrices.sort((a, b) => (a.price_over - b.price_over)).map((d, k) => {
			if (currentCartPrice >= d.price_over && (typeof currentPlaceOrderTypePrices[k + 1] === "undefined" || (typeof currentPlaceOrderTypePrices[k + 1] !== "undefined" && currentCartPrice < currentPlaceOrderTypePrices[k + 1].price_over))) {
				positive_price += parseFloat(d.price);
				returnObject.value_type = 'money';
				returnObject.value = d.price;
			}
		});

		return returnObjectInfo ? returnObject : positive_price;

	}

	// Calculate final price for all products + delivery price - Order Modal 
	static calculateFinalPriceForOrder = (orderType, products, settings, productIngredients, regions, selectedRegion, choosenPlace, calculateAdditionalPrice = true, promocodeData = [], registeredUserDiscount = {}, deliveryRadiusId = null, deliveryShapeId = null, count_cutlery = 0, calculateWithDelivery = true) => {
		const additional_price_each_cutlery = this.getSettingsValue(settings, 'additional_price_each_cutlery')
		let additionalPriceForEachCutleryCalculated = 0;

		var totalCartPrice = parseFloat(this.calculateTotalPriceForOrder(products, settings, productIngredients, calculateAdditionalPrice, orderType));
		let finalCartPrice = totalCartPrice;
		// debugger;
		let positive_price = 0;
		let negative_price = 0;

		if (orderType == 'delivery') {
			// Do not calculcate if region is not selected
			// if (regions.length > 0 && !selectedRegion) {
			// 	return null;
			// }

			// Deliery Discount - FIRST (before delivery prices, because discount is not valid for delivery prices)
			negative_price += this.getOrderTypeDiscount(choosenPlace, 'delivery', finalCartPrice, false, promocodeData)


			if (this.getDeliveryShapes()) {
				if (deliveryShapeId) {
					let myShape = this.getDeliveryShapes().find(r => r.id == deliveryShapeId);

					if (myShape.required_order_price_for_free_delivery && myShape.required_order_price_for_free_delivery > 0 && finalCartPrice >= myShape.required_order_price_for_free_delivery) {
					} else {
						positive_price += parseFloat(myShape.delivery_price)
					}
					// if(myShape.required_order_price_for_free_delivery <= 0 || finalCartPrice < myShape.required_order_price_for_free_delivery) {
					// 	positive_price += myShape.delivery_price
					// }
				}

			} else if (this.getDeliveryRadiuses()) {
				if (deliveryRadiusId) {
					let myRadius = this.getDeliveryRadiuses().find(r => r.id == deliveryRadiusId);
					if (myRadius.required_order_price_for_free_delivery && myRadius.required_order_price_for_free_delivery > 0 && finalCartPrice >= myRadius.required_order_price_for_free_delivery) {
					} else {
						positive_price += parseFloat(myRadius.delivery_price)
					}
					// if(myRadius.required_order_price_for_free_delivery <= 0 || finalCartPrice < myRadius.required_order_price_for_free_delivery) {
					// 	positive_price += myRadius.delivery_price
					// }
					// console.log("CALCULATION 2", myRadius, positive_price);
				}

			} else {
				const orderTypeCurrentPrice = this.getOrderTypePrice(choosenPlace, 'delivery', finalCartPrice, true);
				if (choosenPlace && choosenPlace.ordertypes && orderTypeCurrentPrice && orderTypeCurrentPrice.value) {
					// Delivery price order type - (IF NOT REGIONS)
					positive_price += orderTypeCurrentPrice.value
				} else if (regions.length > 0 && selectedRegion) {
					let selectedRegionPrice = regions.find(r => r.id == selectedRegion);
					selectedRegionPrice = selectedRegionPrice && selectedRegionPrice.delivery_price ? selectedRegionPrice.delivery_price : 0;
					positive_price += parseFloat(selectedRegionPrice);
				}
			}

			if (additional_price_each_cutlery && additional_price_each_cutlery != '0') {
				additionalPriceForEachCutleryCalculated += parseFloat(parseInt(count_cutlery) * parseFloat(additional_price_each_cutlery))
			}

			positive_price = parseFloat(positive_price) + parseFloat(this.calculateBoxesForProducts(products, settings, 'delivery'))

		} else if (orderType == 'preorder') {
			if (additional_price_each_cutlery && additional_price_each_cutlery != '0') {
				additionalPriceForEachCutleryCalculated = parseFloat(parseInt(count_cutlery) * parseFloat(additional_price_each_cutlery));
				additionalPriceForEachCutleryCalculated = parseFloat(additionalPriceForEachCutleryCalculated);
				// finalCartPrice = finalCartPrice + additionalPriceForEachCutleryCalculated;

			}
			positive_price = parseFloat(positive_price) + parseFloat(this.calculateBoxesForProducts(products, settings, 'preorder'))

			// Preorder Discount
			negative_price += this.getOrderTypeDiscount(choosenPlace, 'preorder', finalCartPrice, false, promocodeData)
		}

		negative_price = parseFloat(negative_price);

		finalCartPrice = finalCartPrice - negative_price;

		if (registeredUserDiscount && registeredUserDiscount.discount && registeredUserDiscount.ordersCount &&
			parseInt(registeredUserDiscount.discount.on_order_number) == (parseInt(registeredUserDiscount.ordersCount) + 1)
		) {
			finalCartPrice -= registeredUserDiscount.discount.discount_type == 'money' ? parseFloat(registeredUserDiscount.discount.discount_amount) :
				parseFloat(registeredUserDiscount.discount.discount_amount) * parseFloat(finalCartPrice) / 100
		}

		if (calculateWithDelivery) {
			positive_price = parseFloat(positive_price);
			finalCartPrice = finalCartPrice + positive_price;
		}

		if (additional_price_each_cutlery && additional_price_each_cutlery != '0') {
			finalCartPrice = finalCartPrice + additionalPriceForEachCutleryCalculated;
		}

		finalCartPrice = this.round(finalCartPrice);
		finalCartPrice = parseFloat(finalCartPrice).toFixed(2);

		return finalCartPrice;

		// return (totalCartPrice != finalCartPrice) ? finalCartPrice : null;
	}

	static round(num) {
		return Math.round(num * 100) / 100;
	}

	static handleScrollToOnPop(settings, action, location = null, scrollData = null, forseTop = false) {
		const pageContainerElement = document.getElementById('page-container');
		if (pageContainerElement) {
			pageContainerElement.scrollTo({ left: 0, top: 0 });
		}


		// console.log("SCROL ", action, location, scrollData);
		if (this.getSettingsValue(settings, 'pdf_menu_enabled') == '1') {
			document.querySelector('[data-class="all-products-container"]').scrollIntoView();
		}

		if (forseTop) {
			if (this.getSettingsValue(settings, 'pdf_menu_enabled') == '1') {
				pageContainerElement.scrollTo({ left: 0, top: 0 });
			} else if (document.querySelector('[data-class="swipeable-container"]')) {
				document.querySelector('[data-class="swipeable-container"]').scrollTo({ left: 0, top: 0 });
			}
		} else {

			if (action === "SWIPE") {
				pageContainerElement.scrollTo({ left: 0, top: 0 });

				if (document.querySelector('[data-class="swipeable-container"]')) {
					setTimeout(document.querySelector('[data-class="swipeable-container"]').scrollTo({ left: 0, top: 0 }));
				}

			} else if (action === "POP" && location && scrollData) {
				if (scrollData && scrollData[location.pathname]) {
					setTimeout(() => {
						document.querySelector('[data-class="swipeable-container"]').scrollTo({
							left: 0,
							top: scrollData[location.pathname],
							behavior: "smooth"
						})

						store.dispatch(setScrollData({}))
					}

					);
				} else {
					//  if (document.querySelector('[data-class="swipeable-container"]')) {
					//      document.querySelector('[data-class="swipeable-container"]').scrollTo({ left: 0, top: 0 });
					//  }
				}
			} else if (action === "PUSH") {
				pageContainerElement.scrollTo({ left: 0, top: 0 });
			} else {
				// if (document.querySelector('[data-class="swipeable-container"]')) {
				//      setTimeout(document.querySelector('[data-class="swipeable-container"]').scrollTo({ left: 0, top: 0 }));
				// }
			}
		}

	}

	static hasFunctionalityModule = (functionalityModule) => {
		let currState = store.getState();

		if (currState && currState.places && currState.places.places) {
			let currPlace = currState.places.places.find(p => p.id == Auth.getOpenedPlace());
			if (currPlace && currPlace.functionality_modules) {
				let functionalityModulesForPlace = currPlace.functionality_modules;

				if (functionalityModulesForPlace.find(f => f == functionalityModule)) {
					return true;
				}
			}
		}

		return false;
	}

	static getCurrPlaceData = () => {
		let currState = store.getState();

		if (currState && currState.places && currState.places.places) {
			let p = currState.places.places.find(p => p.id == Auth.getOpenedPlace());
			if (p) return p;
		}

		return null;
	}

	static getDeliveryRadiuses = () => {
		let currPlace = this.getCurrPlaceData();

		if (currPlace && currPlace.ordertypes) {
			let delivery = currPlace.ordertypes.find(p => p.type == 'delivery');

			if (delivery && delivery.radiuses && delivery.radiuses.length > 0) {
				return delivery.radiuses
			}
		}

		return null;
	}

	/*
		distance - in meters
	*/
	static getDeliveryRadiusByDistance = (distance) => {
		if (!distance) return null;

		let currPlace = this.getCurrPlaceData();

		let myRadius = null;
		if (currPlace && currPlace.ordertypes) {
			let delivery = currPlace.ordertypes.find(p => p.type == 'delivery');

			if (delivery.radiuses && delivery.radiuses.length > 0) {
				for (let r = 0; r < delivery.radiuses.length; r++) {
					let currRadius = delivery.radiuses[r];
					if (currRadius.radius_start <= distance && distance < currRadius.radius_end) {
						myRadius = currRadius;
						break;
					}
				}
			}
		}

		return myRadius;
	}

	static getDeliveryShapes = () => {
		let currPlace = this.getCurrPlaceData();

		if (currPlace && currPlace.ordertypes) {
			let delivery = currPlace.ordertypes.find(p => p.type == 'delivery');

			if (delivery && delivery.delivery_shapes && delivery.delivery_shapes.length > 0) {
				let shapes = delivery.delivery_shapes
				for (let i = 0; i < shapes.length; i++) {
					if (shapes[i].coordinates && typeof shapes[i].coordinates == "string") {
						// console.log( "CHECK ", shapes[i].coordinates)
						shapes[i].coordinates = JSON.parse(shapes[i].coordinates)
					}
				}

				return shapes
			}
		}

		return null;
	}

	static setJsCookie = (cname, cvalue, exdays) => {
		const d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		let expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" + (process && process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'production' ? ';secure=true;' : '');
	}

	static saveAddress = (address) => {
		let addresses = window.localStorage.getItem(TABL_USER_ADDRESSES_KEY);

		if (!addresses) addresses = {};
		else addresses = JSON.parse(addresses);

		addresses[address.placeId] = address;

		window.localStorage.setItem(TABL_USER_ADDRESSES_KEY, JSON.stringify(addresses))
	}

	static getUserAddresses = () => {
		return JSON.parse(window.localStorage.getItem(TABL_USER_ADDRESSES_KEY));
	}

	static getUserAddressById = (id) => {
		let addresses = JSON.parse(window.localStorage.getItem(TABL_USER_ADDRESSES_KEY));
		return addresses[id] ?? null;
	}

	static removeUserAddress = (addressId) => {
		let addresses = window.localStorage.getItem(TABL_USER_ADDRESSES_KEY);

		if (!addresses) addresses = {};
		else addresses = JSON.parse(addresses);

		delete addresses[addressId];

		window.localStorage.setItem(TABL_USER_ADDRESSES_KEY, JSON.stringify(addresses))
	}

	static calculateDistance(lattitude1, longittude1, lattitude2, longittude2) {

		const toRadian = n => (n * Math.PI) / 180

		let lat2 = lattitude2
		let lon2 = longittude2
		let lat1 = lattitude1
		let lon1 = longittude1

		// console.log(lat1, lon1 + "===" + lat2, lon2)
		let R = 6371  // km
		let x1 = lat2 - lat1
		let dLat = toRadian(x1)
		let x2 = lon2 - lon1
		let dLon = toRadian(x2)
		let a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
		let d = R * c
		// console.log("distance==?", d)
		return d;

	}


	static getUrlParams(queryString) {
		// Remove the leading '?' if present
		if (queryString.startsWith('?')) {
			queryString = queryString.substring(1);
		}

		// Use URLSearchParams to parse the query string
		const searchParams = new URLSearchParams(queryString);

		// Create an object to hold the parameters
		const params = {};

		// Iterate over the search parameters and add them to the object
		for (const [key, value] of searchParams.entries()) {
			params[key] = value;
		}

		return params;
	}

	static getDeviceAndBrowserDetails() {
        var nVer = navigator.fullVersion;
        var nAgt = navigator.userAgent;
        var browserName  = navigator.appName;
        var fullVersion  = ''+parseFloat(navigator.appVersion); 
        var majorVersion = parseInt(navigator.appVersion,10);
        var nameOffset,verOffset,ix;

        // In Opera, the true version is after "OPR" or after "Version"
        if ((verOffset=nAgt.indexOf("OPR"))!=-1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset+4);
        if ((verOffset=nAgt.indexOf("Version"))!=-1) 
        fullVersion = nAgt.substring(verOffset+8);
        }
        // In MS Edge, the true version is after "Edg" in userAgent
        else if ((verOffset=nAgt.indexOf("Edg"))!=-1) {
        browserName = "Microsoft Edge";
        fullVersion = nAgt.substring(verOffset+4);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset+5);
        }
        // In Chrome, the true version is after "Chrome" 
        else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset+7);
        }
        // In Safari, the true version is after "Safari" or after "Version" 
        else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset+7);
        if ((verOffset=nAgt.indexOf("Version"))!=-1) 
        fullVersion = nAgt.substring(verOffset+8);
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset+8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
                (verOffset=nAgt.lastIndexOf('/')) ) 
        {
        browserName = nAgt.substring(nameOffset,verOffset);
        fullVersion = nAgt.substring(verOffset+1);
        if (browserName.toLowerCase()==browserName.toUpperCase()) {
        browserName = navigator.appName;
        }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix=fullVersion.indexOf(";"))!=-1)
        fullVersion=fullVersion.substring(0,ix);
        if ((ix=fullVersion.indexOf(" "))!=-1)
        fullVersion=fullVersion.substring(0,ix);

        majorVersion = parseInt(''+fullVersion,10);
        if (isNaN(majorVersion)) {
        fullVersion  = ''+parseFloat(navigator.appVersion); 
        majorVersion = parseInt(navigator.appVersion,10);
        }

        return 'Browser name = '+browserName+" "
                +'Full version = '+fullVersion+" "
                +'Major version = '+majorVersion+" "
                +'navigator.appName = '+navigator.appName+" "
                +'navigator.userAgent = '+navigator.userAgent+" "

    }

}

export default Utils;