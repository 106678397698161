import { SET_CURRENT_USER, SET_LOGIN_MODAL_OPENED, SET_REGISTER_MODAL_OPENED, SET_PROFILE_MODAL_OPENED, 
    SET_RESET_PASSWORD_MODAL_OPENED, GET_USER_PAYMENT_INSTRUMENTS, GET_USER_ORDERS, SET_WAITER_TABLE_MODAL_OPENED,
    GET_TABLES } from '../actions/types';
import isEmpty from 'lodash/isEmpty';

const initialState = {
    isAuthenticated: false,
    user: {},
    auth_error: "",
    login_modal_opened: false,
    register_modal_opened: false,
    profile_modal_opened: false,
    reset_password_modal_opened: false,
    payment_instruments: [],
    orders: [],
    waiter_table_modal_opened: false,
    tables: [],
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.user),
                user: action.user
            }
        case SET_LOGIN_MODAL_OPENED: 
            return {
                ...state,
                login_modal_opened: action.state
            }
        case SET_REGISTER_MODAL_OPENED: 
            return {
                ...state,
                register_modal_opened: action.state
            }
        case SET_PROFILE_MODAL_OPENED: 
            return {
                ...state,
                profile_modal_opened: action.state
            }
        case SET_RESET_PASSWORD_MODAL_OPENED: 
            return {
                ...state,
                reset_password_modal_opened: action.state
            }
        case GET_USER_PAYMENT_INSTRUMENTS: 
            return {
                ...state,
                payment_instruments: action.payload
            }
        case GET_USER_ORDERS: 
            return {
                ...state,
                orders: action.payload
            }
        case SET_WAITER_TABLE_MODAL_OPENED: 
            return {
                ...state,
                waiter_table_modal_opened: action.state
            }
        case GET_TABLES: 
            return {
                ...state,
                tables: action.payload
            }
        default: return state;
    }
}