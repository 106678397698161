import {
    GET_FREE_HOURS_FOR_DATE, GET_BUSY_DATES, RESERVATION_CREATED, RESERVATION_CENCELED,
    GET_FREE_RESTAURANT_HALLS, FREE_TABLES_FOR_TIME, GET_ALLOWED_PEOPLE_FOR_TIME, SET_LOADING, GET_CURRENT_RESERVATION_INFO
} from './../actions/types';

const initialState = {
    freeHours: [],
    busyDates: [],
    freeRestaurantHalls: [],
    loading: false,

    reservation_created: [],
    reservation_id: 0,
    reservation_canceled: false,
    reservation_free_tables_for_time: true,
    reservation_allowed_people_for_time: [],
    reservations_online_payment_paysera_activated: false,
    current_reservation: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FREE_HOURS_FOR_DATE:
            return {
                ...state,
                freeHours: action.payload,
            }
        case GET_BUSY_DATES:
            return {
                ...state,
                busyDates: action.payload,
            }
        case RESERVATION_CREATED:
            return {
                ...state,
                reservation_created: true,
                reservation_error: [],
                reservation_id: action.payload.reservation_id,
            }
        case RESERVATION_CENCELED:
            return {
                ...state,
                reservation_canceled: true,
            }
        case GET_FREE_RESTAURANT_HALLS:
            return {
                ...state,
                freeRestaurantHalls: action.payload
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case FREE_TABLES_FOR_TIME:
            return {
                ...state,
                reservation_error: [],
                reservation_free_tables_for_time: action.payload
            }
        case GET_ALLOWED_PEOPLE_FOR_TIME:
            return {
                ...state,
                reservation_error: [],
                reservation_allowed_people_for_time: action.payload
            }
        case GET_CURRENT_RESERVATION_INFO:
            return {
                ...state,
                current_reservation: action.payload
            }
        default:
            return state;
    }
}