import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Avatar from '@material-ui/core/Avatar';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Utils from '../../utils/Utils'
import { Typography } from '@material-ui/core';
import Auth from '../../utils/Auth';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 700,
        '@media screen and (max-width: 1000px)': {
            margin: '0 auto !important',
        }
    },
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridColumnGap: 10,
    itemTypography: {
        display: 'contents',
    },
    subcatRow: {
        '&:first-child': {
            marginTop: 15,
        },
        '&:last-child': { // temp unable to hive navbar fix
            marginBottom: 100,
        },
        background: 'rgba(255, 255, 255, 0.65)',
        width: '95%',
        margin: '0 auto',
        marginBottom: 10,
        borderRadius: 7,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.71)',
        },
        padding: 8,
    },
    avatar: {
        width: 55,
        height: 55,
        marginRight: 15,
        border: '1px solid #ccc',
    },
    rightArrowIcon: {
        padding: 5,
        background: 'rgba(255, 255, 255, 0.16)',
        borderRadius: 25,
    },
}));

export default function RowButton(props) {
    const classes = useStyles();

    const { registeredUserDiscount } = props;
    const token_not_spot = Auth.getTokenOrderType() != 1;
    const token_spot = Auth.getTokenOrderType() == 1;
    const token_not_spot_browse = Auth.getTokenOrderType() != 4;
    const token_spot_browse = Auth.getTokenOrderType() == 4;
    const token_not_delivery = Auth.getTokenOrderType() != 2;
    const token_delivery = Auth.getTokenOrderType() == 2;

    return (
        <div className={classes.root} data-rowbutton={1} style={{
            width: '100%', justifySelf: 'end', margin:
                ((props.settings.promotions_html && props.settings.promotions_html.value)
                    || (props.settings.promotions_text && props.settings.promotions_text.value)
                    || (registeredUserDiscount && registeredUserDiscount.discount && Object.values(registeredUserDiscount.discount).length > 0)
                )
                    ? 'inherit' : '0 auto'
        }}>
            {Object.values(props.items).sort(Utils.sortPriority()).map((item, k) => {

                if (token_not_spot && item.show_cat_only_for_spot_orders == 1) { return null }
                if (token_spot && item.hide_cat_only_for_spot_orders == 1) { return null }
                if (token_not_spot_browse && item.show_only_for_spot_browse == 1) { return null }
                if (token_spot_browse && item.hide_only_for_spot_browse == 1) { return null }
                if (token_delivery && item.hide_cat_only_for_delivery_orders == 1) { return null }
                if (token_not_delivery && item.show_cat_only_for_delivery_orders == 1) { return null }

                return (
                    <Grow in={true} timeout={{ enter: Utils.calculateAnimationTimeout(k), exit: Utils.calculateAnimationTimeout(k) }} key={k}>

                        <ListItem className={classes.subcatRow} button onClick={() => props.categoryClickHandler(item.id)} >
                            <Typography component="h3" variant="span" className={classes.itemTypography}>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar} src={Utils.getSubcategoryImagePath(item.icon, 1)} alt={item && item.translations && props.currLang && item.translations[props.currLang] && item.translations[props.currLang].name || item.name} />
                                </ListItemAvatar>

                                <ListItemText
                                    primary={
                                        <span style={{ fontSize: '1.1rem' }}>
                                            {/* {item && item.translations && props.currLang && item.translations[props.currLang] && item.translations[props.currLang].name || item.name} */}
                                            {item && item.translations && props.currLang && item.translations[props.currLang] && (Utils.getSettingsValue(props.settings, 'replace_slash_with_new_line_category_list') == 1 ? Utils.replaceString(item.translations[props.currLang].name, '/', "<br/>", 1) : item.translations[props.currLang].name) || (Utils.getSettingsValue(props.settings, 'replace_slash_with_new_line_category_list') == 1 ? Utils.replaceString(item.name, '/', "<br/>", 1) : item.name)}
                                        </span>
                                    }
                                    secondary={<div dangerouslySetInnerHTML={{
                                        __html: item.description ? item.description : ''
                                    }}></div>}
                                // secondary={(item.products_count ? item.products_count : 0) + (item.products_count == 1 ? (props.translations && props.translations.common ? (' ' + props.translations.common.product) : '') : (props.translations && props.translations.common ? (' ' + props.translations.common.products) : ''))}
                                />
                                <KeyboardArrowRightIcon className={classes.rightArrowIcon} />
                            </Typography>
                        </ListItem>

                    </Grow>
                )
            })}
        </div>
    );
}
