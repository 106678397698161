import { OPEN_SCAN_MODAL, CLOSE_SCAN_MODAL, SET_CURR_OPENED_MODAL, SET_ORDER_MODAL_STATE } from '../actions/types';

const initialState = {
    scan_modal_open: 0,
    curr_opened_modal:  '' ,
    order_modal_state: 0
}

export default function (state = initialState, action) {
    switch (action.type) {
        case OPEN_SCAN_MODAL:
            return {
                ...state,
                scan_modal_open: action.payload
            }
        case CLOSE_SCAN_MODAL:
            return {
                ...state,
                scan_modal_open: action.payload
            }
        case SET_CURR_OPENED_MODAL:
            return {
                ...state,
                curr_opened_modal: action.payload
            }
        case SET_ORDER_MODAL_STATE:
            return {
                ...state,
                order_modal_state: action.payload
            } 
        default:
            return state;
    }
}