export const customCssStyles = {
    alergensCollapseContainer: {
        '& .MuiCollapse-wrapper': {
            display: 'block',
        },
    },
    alergensCointainer: {
        padding: 10,
        paddingTop: 15,
    },
    alergensListItem: {
        padding: 0,
        // paddingBottom: 0,
    },
    alergenIcon: {
        width: 25,
        marginRight: 20
    },

    oldPrice: {
        fontWeight: 'normal !important'
    }
}