import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

class MetaAndOg extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { settings, subcategory, image } = this.props;

        const meta_title_place_name = settings.meta_title_place_name && settings.meta_title_place_name.value && settings.meta_title_place_name.value.length > 0 ? settings.meta_title_place_name.value : '';
        const meta_description = settings.meta_description && settings.meta_description.value && settings.meta_description.value.length > 0 ? settings.meta_description.value : '';
        const og_image_link = settings.og_image_link && settings.og_image_link.value && settings.og_image_link.value.length > 0 ? settings.og_image_link.value : 'https://tabl.bg/img/tablbg-aboutus-og.png';

        {/* TODO: Get lang */ }
        const title = (subcategory && meta_title_place_name.length > 0) ? 'TabL - ' + subcategory + ' - ' + meta_title_place_name : (meta_title_place_name.length > 0 ? meta_title_place_name + ' - Дигитално меню от TabL' : 'Дигитално меню и система за поръчки');
        const description = (subcategory && meta_title_place_name.length > 0) ? 'TabL - Виж повече "' + subcategory + '" от ' + meta_title_place_name : (meta_description.length > 0 ? 'TabL - ' + meta_description : "TabL - Разгледай електронното меню на заведения около теб и поръчай веднага като стигнеш!");
        const ogImage = subcategory ? image : og_image_link;
        return null;
        return (
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={ogImage} />
                <link rel="canonical" href={window.location.origin + (window.location.pathname.includes('/') ? '/' + window.location.pathname.split('/')[1] : '')} />
                <meta property="og:url" content={window.location.origin + (window.location.pathname.includes('/') ? '/' + window.location.pathname.split('/')[1] : '')} />
                <meta property="og:type" content="food" />
            </Helmet>
        );

    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.settings.settings || [],
    };
};

export default connect(mapStateToProps, {})(MetaAndOg);