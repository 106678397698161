import { CHECK_PROMOCODE } from './types';

const initialState = {
    promocodeData: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHECK_PROMOCODE:
            return {
                ...state,
                promocodeData: action.payload
            }
        default:
            return state;
    }
}