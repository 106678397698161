import axios from 'axios';
import {
    GET_CATEGORIES, SET_CHOSEN_CAT, SHOW_CATEGORY, SET_CATEGORIES_LOADED, GET_CATEGORIES_HIERARCHY,
    LOADING_HIERARCHY_START, LOADING_HIERARCHY_END, SET_HIERARCHY_LEVEL, SET_SELECTED_CAT_ID,
} from './types';
import { LOADING_START, LOADING_END } from '../../products/actions/types';
import { API_URL } from '../../constants';

export const getCategories = () => dispatch => {
    dispatch({ type: LOADING_START });

    axios
        .get(API_URL + 'categoriesWithSubcategories?active=1&subcatRequired=1&productRequired=1&productActive=1')
        .then(res => {
            dispatch({
                type: GET_CATEGORIES,
                payload: res.data
            });
            dispatch({ type: LOADING_END });
        }).catch(error => {
            throw (error);
        });
}

// it sets the index in the catArray not the id 
export const setChosenCat = (catIndex) => dispatch => {
    dispatch({
        type: SET_CHOSEN_CAT,
        payload: catIndex
    })
}

export const setSelectedCatId = (catId) => dispatch => {
    dispatch({
        type: SET_SELECTED_CAT_ID,
        payload: catId
    })
}

export const setCategoriesLoadedFlag = (flag) => dispatch => {
    dispatch({
        type: SET_CATEGORIES_LOADED,
        payload: flag
    })
}

export const showHeaderCategory = (catName) => dispatch => {
    dispatch({
        type: SHOW_CATEGORY,
        payload: catName
    })
}

export const getCategoriesHierarchy = (/*place_id = 0*/) => dispatch => {
    dispatch({ type: LOADING_HIERARCHY_START });

    let url = 'categoriesHierarchy?active=1';
    // if (place_id !== 0) {
    //     url += '&place_id=' + place_id;
    // }

    axios
        .get(API_URL + url)
        .then(res => {
            dispatch({
                type: GET_CATEGORIES_HIERARCHY,
                payload: res.data
            });
            dispatch({ type: LOADING_END });
            dispatch({ type: LOADING_HIERARCHY_END });
        }).catch(error => {
            dispatch({ type: LOADING_HIERARCHY_END });
            throw (error);
        });
}

export const setHierarchyLevel = (level) => dispatch => {
    dispatch({
        type: SET_HIERARCHY_LEVEL,
        payload: level
    });
}
