export const designV2_CommonCss = {
    root: {
        display: 'flex',
        backgroundColor: '#fafafa',
        // borderBottomRightRadius: 0,
        // borderBottomLeftRadius: 0,
        flexDirection: 'column-reverse',
    },
    content: {
        padding: 7,
        // paddingBottom: 0,
    },
    cover: {
        width: '100%',
        height: 100,
        boxShadow: 'inset 0px -7px 7px 0px #fafafa',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    coverContain: {
        width: '100%',
        height: 200,
        boxShadow: 'inset 0px -7px 7px 0px #fafafa',
        backgroundSize: 'contain', //USED only for the "no-product-image.svg" 
    },
    options: {
        fontStyle: 'italic',
    },
    addToCartButton: {
        width: '100%',
        marginTop: -6,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 3px 0px rgba(0,0,0,0.12)',
    },
    variantsContainer: {
        '&:first-child': {
            marginTop: 10,
        },
        borderBottom: '1px solid rgba(0,0,0,0.12)',
        wordBreak: 'break-word',
        display: 'inline-flex',
        fontSize: '0.95rem',
        color: '#6e6e6e',
        width: '100%',
        padding: 5,
        margin: 5,
    },
    productName: {
        lineHeight: 1.3,
        fontSize: '1.1rem',
        textAlign: 'center',
    },
    productDescription: {
        lineHeight: 1.2,
        fontSize: '0.9rem',
        paddingTop: 10,
        fontStyle: 'italic',
    }
}

export const designV2_1Css = {
    addToCartButton: {
        '&:hover': {
            background: '#f86500'
        },
        background: '#e75e00',
        color: 'white',
    }
}

export const designV2_2Css = {
    addToCartButton: {
        color: '#e75e00',
    }
}