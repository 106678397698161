import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import PostAddIcon from '@material-ui/icons/PostAdd';
import TextField from '@material-ui/core/TextField';
import { bgTranslations } from '../../language/translations/bg';
import Utils from '../../utils/Utils';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  variantButton: {
    width: '100%',
    padding: 10,
    paddingLeft: 10,
    background: '#f6f6f6',
    marginTop: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  borderedVariantButton: {
    border: '1px solid #ccc',
    borderBottom: '0',
  },
  variantCommentTextfield: {
    border: '1px solid #ccc',
    borderTop: '0',
    boxSizing: 'border-box',

    width: '100%',
    // Focused orange text color
    '& label.Mui-focused': {
      color: 'rgb(255, 148, 74)',
    },

    '& .MuiOutlinedInput-root': {
      height: '45px !important',
      borderTopLeftRadius: '0px !important',
      borderTopRightRadius: '0px !important',

      // Focused border color
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(206, 206, 206)',
      },

      // Not focused textfield
      '& .MuiOutlinedInput-notchedOutline': {
        'border-color': 'rgb(246, 246, 246)',
        /* box-shadow: 0px 1px 1px #b8b8b8; */
        'border-width': '0',
      }
    },
    // Label center
    '& .MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
      transform: 'translate(14px, 15px) scale(1)',
    },
  },
  varaintPriceButton: {
    textAlign: 'center',
    backgroundColor: 'rgb(203, 65, 2)',
    color: 'white',
    padding: 5,
    borderRadius: 6,
    'box-shadow': '0 0 2px #747474',
  },
}));

export default function ProductVariant(props) {
  let { translations, variantModalWithComment, order_comment, productIngredients } = props;
  translations = translations.translations ? translations.translations : translations;

  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xs');

  const [currProdComments, setCurrProdComments] = React.useState(order_comment ? Object.values(order_comment) : []);

  // Onchange set comment to state
  const setComment = (variantId, comment) => {
    let stateComments = Object.values(currProdComments);
    let validatedComments = Utils.validatedCommentObject(stateComments, variantId, comment)
    if (validatedComments) {
      setCurrProdComments(stateComments)
    }
  }

  const addCommentToOrder = (variantId) => {
    const currProdVariantComment = currProdComments.filter(c => c.variantId == variantId)[0];
    if (currProdVariantComment) {
      props.setOrderComment(Utils.validatedCommentObject(Object.values(order_comment), currProdVariantComment.variantId, currProdVariantComment.comment))
    }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={() => { setCurrProdComments([]); props.closeProductVariantModalHandler() }}
        aria-labelledby="max-width-dialog-title"
        TransitionComponent={Grow}
        data-class="dialog-container"
      >
        <DialogTitle id="max-width-dialog-title" style={{ textAlign: 'center', backgroundColor: "rgb(203,65,2)", color: 'white', boxShadow: '0 1px 5px 0px black' }} data-class="dialog-title-container">
          {Utils.truncate(props.product && props.product.name ? props.product.name : (translations && translations.pages && translations.pages.products ? translations.pages.products.choose_variant : ''), 22, 20)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: 10 }}>

            {props.product && (props.product.product_variants).map(pv => {
              let currentVariantComment = order_comment ? order_comment.filter(c => c.variantId == pv.id) : ''
              if (currentVariantComment && currentVariantComment[0] && currentVariantComment[0].comment) {
                currentVariantComment = currentVariantComment[0].comment;
              } else {
                currentVariantComment = '';
              }

              return (
                <React.Fragment>

                  <Button className={variantModalWithComment ? clsx(classes.borderedVariantButton, classes.variantButton) : classes.variantButton} onClick={() => { setCurrProdComments([]); props.addProdToCart(props.product.id, pv.id, props.product, productIngredients); addCommentToOrder(pv.id) }}>

                    <Grid container direction="row" alignItems="center"> {/* justify="space-between" */}

                      {variantModalWithComment ?
                        null :
                        <Grid item style={{ fontSize: 13, textAlign: 'left', width: 35 }}>
                          <PostAddIcon />
                        </Grid>
                      }

                      <Grid item style={{ fontSize: 13, textAlign: 'left', width: 'calc(100% - 110px)', textAlign: (variantModalWithComment ? 'left' : 'center') }}>
                        {pv.translations[props.currLang] && pv.translations[props.currLang].size || pv.size}
                      </Grid>

                      <Grid item style={{ textAlign: 'right', fontSize: 13, width: (variantModalWithComment ? 110 : 75) }}>
                        {variantModalWithComment ?
                          <div className={classes.varaintPriceButton}>
                            {pv.price} {props.settings.default_currency_suffix && props.settings.default_currency_suffix.value ? props.settings.default_currency_suffix.value : 'лв.'}
                          </div>
                          :
                          <React.Fragment>
                            {pv.price} {props.settings.default_currency_suffix && props.settings.default_currency_suffix.value ? props.settings.default_currency_suffix.value : 'лв.'}
                          </React.Fragment>
                        }
                      </Grid>


                    </Grid>

                  </Button>

                  {variantModalWithComment ?
                    <TextField id="outlined-basic" label={translations && translations.common && translations.common.comment ? translations.common.comment : ''} variant="outlined" className={classes.variantCommentTextfield} onChange={(e) => setComment(pv.id, e.target.value)} defaultValue={currentVariantComment} />
                    :
                    null
                  }

                </React.Fragment>
              )
            }
            )}
            <br />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}