export const designV1_CommonCss = {
    root: {
        display: 'flex',
        backgroundColor: '#fafafa',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    details: {
        width: '100%'
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 230,
        boxShadow: 'inset 2px -11px 7px 0px #fafafa',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    coverContain: {
        width: '100%',
        height: 200,
        boxShadow: 'inset 0px -7px 7px 0px #fafafa',
        backgroundSize: 'contain', //USED only for the "no-product-image.svg" 
    },
    options: {
        position: 'relative',
        fontStyle: 'italic',
        paddingLeft: 15,
        paddingRight: 10,
    },
    addToCartBtnContainer: {
        position: 'absolute',
        bottom: 10,
        left: '125%',
    },
    addToCartButton: {
        width: '100%',
    },
    variantsContainer: {
        borderBottom: '1px solid rgba(0,0,0,0.12)',
        wordBreak: 'break-word',
        display: 'inline-flex',
        fontSize: '0.95rem',
        width: '100%',
        marginBottom: 8,
        paddingBottom: 5,
        paddingTop: 5,
    },
    productName: {
        lineHeight: 1.2,
        marginBottom: 10,
        fontSize: '1.05rem',
    },
    productDescription: {
        lineHeight: 1.2,
        fontSize: '0.95rem',
        background: '#fafafa',
        fontStyle: 'italic',
    }
}

export const designV1_1Css = {
    options: {
        paddingBottom: 10, // V1
    },
    addToCartButton: {
        color: '#FE6902',
        borderRadius: 0,
        border: '1px solid #fe6902',
    },
    productDescription: {
        padding: 10,
        paddingTop: 3,
        // textAlign: 'center',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 3px 0px rgba(0,0,0,0.12)',
    }
};

export const designV1_2Css = {
    options: {
        paddingBottom: 0, // V2
    },
    addToCartButton: {
        '&:hover': {
            background: '#f86500'
        },
        background: '#e75e00',
        color: 'white',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 3px 0px rgba(0,0,0,0.12)',
    },

};